import React, { useEffect, useState, useRef } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { useLoading } from "../../hooks/useLoading";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import { titleCase } from "../../helpers/format";
import ColumnInTable from "../../components/ColumnInTable";
import { toast } from "react-toastify";
const DEACTIVATE = "Deactivate";
const REACTIVATE = "Reactivate";
const UpSellListInLicensee = () => {
  const { licenseeId, firstname } = useParams();
  const history = useHistory();
  const [upsellList, setUpsellList] = useState([]);
  const [showLoading, hideLoading] = useLoading();
  useEffect(() => {
    getAll();
  }, []);

  // HANDLE OPEN DIALOG
  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });
  // HANDLE OPEN DIALOG
  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header:
            "Are you sure you want to deactivate this upsell item from the licensee ?",
          subtitle:
            "The Licensee will lose access to this items. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate item",
          onActive: () => handleChangeActivate(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header:
            "Are you sure you want to reactivate this upsell item from the licensee ?",
          subtitle: "The Licensee will gain access to this item again",
          submitTitle: "Reactivate item",
          onActive: () => handleChangeActivate(data.id),
        });
      }
    }
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }
  function getAll() {
    showLoading();
    axios
      .get("/v1/admin/licensee/trailers-Upsells/" + licenseeId)
      .then((response) => {
        hideLoading();
        const list = response.data.licenseeUpsellItemsObj
        return setUpsellList(setNumberForEachUpsellItemInList(list))
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function handleChangeActivate(id) {
    showLoading();
    axios
      .put(`v1/admin/upsellitem-licensee-active/${licenseeId}/${id}`)
      .then((response) => {
        hideLoading();
        getAll();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function returnMockData() {
    return [
      {
        title: <span className="title-table">Upsell Item</span>,
        field: "trailerType",
        render: (rowData) => {
          return (
            <div style={{ minWidth: "450px", marginRight: "25px" }}>
              {rowData.upsellItemId.name + "-" +rowData.number}
            </div>
          );
        },
      },
      {
        title: "Item Type",
        field: "itemType",
        render: (rowData) => {
          return (
            <ColumnInTable minWidth="194">
              {titleCase(
                rowData.upsellItemId?.consumeable
                  ? "Consumable Item"
                  : "Upsell Item"
              )}
            </ColumnInTable>
          );
        },
      },

      {
        title: "Price",
        field: "price",
        render: (rowData) => {
          return (
            <ColumnInTable minWidth="164">
              {rowData.upsellItemId.consumeable
                ? "$" + Math.ceil(rowData.price)
                : "$" + Math.ceil(rowData.hireCost) + " per day"}
            </ColumnInTable>
          );
        },
      },
      {
        title: "Description",
        field: "description",
        render: (rowData) => {
          return (
            <div
              style={{
                maxWidth: "100px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {rowData.upsellItemId.description}
            </div>
          );
        },
      },
      {
        title: "",
        field: "action",
        render: (rowData) => {
          return (
            <WrapButtonFlexRight>
              <ButtonActionSubPage
                colorError={rowData.isActive ? true : false}
                onClick={() => {
                  toggleDialog(
                    {
                      id: rowData._id,
                      isActive: rowData.isActive,
                    },
                    "active"
                  );
                }}
              >
                {rowData.isActive ? DEACTIVATE : REACTIVATE}
              </ButtonActionSubPage>
            </WrapButtonFlexRight>
          );
        },
      },
    ];
  }
  function setNumberForEachUpsellItemInList(list) {
    let countSimilarId = {}
    return list.reduce((prev, current) => {
      if (countSimilarId[current.upsellItemId._id]) {
        countSimilarId = {...countSimilarId, [current.upsellItemId._id]: ++countSimilarId[current.upsellItemId._id]}
        return [...prev, {...current, number: countSimilarId[current.upsellItemId._id] }]
      }
      countSimilarId = {...countSimilarId, [current.upsellItemId._id]: 1 }
      return [...prev, {...current, number: 1 }]
    },[]);
  }
  return (
    <div>
      <WrapHeaderSubPage nameHeader={`${firstname}'s Upsell Items`}>
        <ButtonActionHeader
          onClick={() =>
            history.push(`/licensee/${firstname}/${licenseeId}/upsellitems/add`)
          }
        >
          Add Item
        </ButtonActionHeader>
      </WrapHeaderSubPage>
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        columns={returnMockData()}
        data={upsellList}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};
export default UpSellListInLicensee;
