import React, { useEffect, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { Box, makeStyles, Typography } from "@material-ui/core";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import { useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
const Featured = () => {
  const [trailerList, setTrailerList] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    axios.get(`v1/admin/get-all-features`).then((response) => {
      const results = response.data.featuresTrailersObj;
      setTrailerList(
        results.map((item) => ({
          _id: item._id,
          trailer: item.trailerId,
          backgroundColor: item.color,
        }))
      );
    });
  }, []);
  return (
    <>
      <WrapHeaderSubPage nameHeader="Featured Trailers" />
      <Box
        display="flex"
        justifyContent="space-between"
        paddingBottom="25px"
        flexWrap="wrap"
        marginX="-10.5px"
      >
        {trailerList.map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            margin="0 10.5px 21px 10.5px"
          >
            <Box
              width="314px"
              height="268px"
              padding="36px 40px 31px 40px"
              boxSizing="border-box"
              borderRadius="15px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              style={{
                backgroundColor: item.backgroundColor,
              }}
            >
              {item.trailer === null ? (
                <>
                  <div style={{ width: "212px", height: "158px" }}></div>
                  <Typography classes={{ root: classes.itemTitle }}>
                    Don't have trailer
                  </Typography>
                </>
              ) : (
                <>
                  <img
                    src={item.trailer.photos[0].url}
                    style={{ width: "212px", height: "158px" }}
                  />
                  <Typography classes={{ root: classes.itemTitle }}>
                    {item.trailer?.name}
                  </Typography>
                </>
              )}
            </Box>
            <ButtonActionHeader
              onClick={() =>
                history.push({
                  pathname: `/featured-trailer/${item._id}`,
                  state: {
                    trailer: item.trailer,
                  },
                })
              }
              style={{ marginTop: "24px" }}
            >
              {item.trailer === null ? "Add" : "Change"}
            </ButtonActionHeader>
          </Box>
        ))}
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  itemTitle: {
    fontSize: 18,
    lineHeight: "21.48px",
    fontWeight: 600,
    textAlign: "center",
  },
}));
export default Featured;
