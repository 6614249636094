import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ColumnInTable from "../../components/ColumnInTable";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { ReactComponent as Edit } from "../../img/edit.svg";
import { useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { titleCase } from "../../helpers/format";
import { toast } from "react-toastify";

const UpsellList = () => {
  const history = useHistory();
  const [upsellList, setUpsellList] = useState([]);
  useEffect(() => {
    axios
      .get("/v1/admin/get-all-upsellitems")
      .then((response) => {
        return setUpsellList(response.data.dataObj);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);
  const mockData = [
    {
      title: <span className="title-table">Upsell type</span>,
      field: "upsellType",
      render: (rowData) => {
        return (
          <div
            className="list-item-name"
            style={{ minWidth: "540px", marginRight: "5px" }}
          >
            {rowData.name}
          </div>
        );
      },
    },

    {
      title: "Item Type",
      field: "type",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="185">
            {rowData.consumeable ? "Consumable Item" : "Upsell Item"}
          </ColumnInTable>
        );
      },
    },

    {
      title: "Price",
      field: "price",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="165">
            {rowData.consumeable
              ? "$" + Math.ceil(rowData.price)
              : "$" + Math.ceil(rowData.hirecost) + " per day"}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="175">
            <div
              style={{
                maxWidth: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {rowData.description}
            </div>
          </ColumnInTable>
        );
      },
    },

    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            onClick={() => history.push(`/rental/upsell/edit/${rowData._id}`)}
          >
            <Edit style={{ marginRight: 8.5, cursor: "pointer" }} />
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <WrapHeaderSubPage nameHeader="All Upsell Items">
        <ButtonActionHeader onClick={() => history.push("/rental/upsell/add")}>
          Add Upsell
        </ButtonActionHeader>
      </WrapHeaderSubPage>
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={upsellList}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};

export default UpsellList;
