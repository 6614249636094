import { Button, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../img/logo2.svg";
import { ReactComponent as Break } from "../../img/break.svg";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../helpers/axios";

const AcceptInvite = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [userData, setUserData] = useState();
  let history = useHistory();

  useEffect(() => {
    if (id) {
      axios.get("v1/admin/detailuser/" + id).then((response) => {
        return setUserData(response.data.userDetail);
      });
    }
  }, [id]);
  return (
    <div className="reset-email">
      <Logo className="logo" />
      <div className="box-main">
        <Typography variant="h3" className="title">
          {`Hi ${userData?.name}, You have been invited to the Trailer2You Admin Panel`}
        </Typography>
        <Break className="break" />
        <span className="text-16 description">
          Accept the invite then set a password in the panel to get access.
        </span>
        <Button
          type="submit"
          className={classes.btnSubmit}
          color="primary"
          variant="contained"
          onClick={() => history.push(`/set-password/${id}`)}
        >
          Accept Invite
        </Button>
      </div>
    </div>
  );
};

export default AcceptInvite;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
}));
