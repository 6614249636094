import React from "react";
import { Box, Typography } from "@material-ui/core";
import { isImgFile } from "../../helpers/verify";
import { ReactComponent as CloneAvatar } from "../../img/cloneAvatar.svg";
import AvatarAdminUser from "../../pages/admin_user/AvatarAdminUser";
import ButtonActionSubPage from "../ButtonActionSubPage";
import { imageItemStyles } from "./style";
import { nonAvatarStyle } from "./style";
const TemplateImageItemLicensee = ({ onAddImage, dataImage }) => {
  async function AddImage(event) {
    if (event.target.files && isImgFile(event.target.files[0])) {
      const file = event.target.files[0];
      onAddImage({
        url: URL.createObjectURL(file),
        file: file,
      });
    }
  }
  const cssImageItemLicensee = imageItemStyles();
  return (
    <Box classes={cssImageItemLicensee}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          margin="0 25px 40px 25px"
          marginBottom="40px"
          overflow="hidden"
        >
          {dataImage?.url !== "" ? (
            <AvatarAdminUser url={dataImage.url} />
          ) : (
            <CloneAvatar style={{ ...nonAvatarStyle }} />
          )}
        </Box>
        <input
          accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
          style={{ display: "none" }}
          id="jpg-file"
          type="file"
          onChange={AddImage}
        />
        <label htmlFor="jpg-file">
          <ButtonActionSubPage minWidth="360px" height="52px" component="span">
            Upload Photo
          </ButtonActionSubPage>
        </label>
        <Typography align="center" style={{ marginTop: "13px" }}>
          PNG, JPG, JPEG
        </Typography>
      </Box>
    </Box>
  );
};
export default TemplateImageItemLicensee;
