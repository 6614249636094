import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers = {
    "Content-Type": "application/json",
  };
  if (token) {
    config.headers["Authorization"] = token;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  response => { return response;
  },
  error => {
    const statusCode = error.response.status;
    if (statusCode === 404) {
      window.location.href = '/404';
      return;
    }
    if (statusCode === 401) {
      window.location.href = '/signin';
      return;
    }
    if (statusCode === 403) {
      window.location.href = '/forbidden';
      return;
    }
    throw error;
  },
);

export default axiosInstance;
