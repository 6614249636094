import { Box } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import ColumnInTable from "../../components/ColumnInTable";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { ReactComponent as Edit } from "../../img/edit.svg";
import { useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";

const TrailerList = () => {
  const history = useHistory();
  const [trailerList, setTrailerList] = useState([]);
  useEffect(() => {
    axios
      .get("/v1/admin/get-all-trailers")
      .then((response) => {
        return setTrailerList(response.data.dataObj);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);
  const mockData = [
    {
      title: <span className="title-table">Trailer type</span>,
      field: "trailerType",
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: "465px",
              whiteSpace: "nowrap",
              paddingRight: 10,
            }}
          >
            {rowData.name}
          </div>
        );
      },
    },
    {
      title: "Size",
      field: "size",
      render: (rowData) => {
        return <ColumnInTable minWidth="132">{rowData.size}</ColumnInTable>;
      },
    },
    {
      title: "Axle",
      field: "axle",
      render: (rowData) => {
        return <ColumnInTable minWidth="132">{rowData.axel}</ColumnInTable>;
      },
    },
    {
      title: "Tare",
      field: "tare",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="132">{rowData.tare + "kg"}</ColumnInTable>
        );
      },
    },
    {
      title: "Max Load",
      field: "maxLoad",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="132">{rowData.maxLoad + "kg"}</ColumnInTable>
        );
      },
    },
    {
      title: "GVM",
      field: "GVM",
      render: (rowData) => {
        return <ColumnInTable minWidth="132">{rowData.gvm}kg</ColumnInTable>;
      },
    },
    {
      title: "Hire Cost",
      field: "hireCost",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="152">
            ${Math.ceil(rowData.hirecost)} per day
          </ColumnInTable>
        );
      },
    },

    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            onClick={() =>
              history.push({
                pathname: `/rental/trailer/edit/${rowData._id}`,
                state: { allTrailer: trailerList },
              })
            }
          >
            <Edit style={{ marginRight: 8.5, cursor: "pointer" }} />
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <WrapHeaderSubPage nameHeader="All Trailers">
        <ButtonActionHeader onClick={() => history.push("/rental/trailer/add")}>
          Add Trailer
        </ButtonActionHeader>
      </WrapHeaderSubPage>
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={trailerList}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
    </>
  );
};

export default TrailerList;
