import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  buttonSelect: {
    minWidth: "50px",
    height: "32px",
    padding: "9px 5px 9px 8px",
    margin: "0 25px 0 8px",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#53555b",
    },
    "& .MuiButton-endIcon": {
      marginLeft: 0,
      marginRight: "-1px",
    },
    fontSize: "12px",
    letterSpacing: "normal",
    backgroundColor: "#53555b",
    justifyContent: "space-between",
  },
  menu: {
    backgroundColor: "#53555b",
  },
  isPaginationBottom: {
    marginTop: "-4px"
  },
  list: {
    width: "50px",
  },
// 
  item: {
    fontSize: 12,
    color: "#FFFFFF",
    "&.Mui-selected": {
      backgroundColor: "rgba(0,0,0,0.3) !important"
    }
  },
// 
  endIconButtonSelect: {
    color: "#44D62C",
  },
  typography: {
    color: "#FFFFFF",
    fontSize: 14,
  },
  navigateButton: {
    minWidth: "fit-content",
    color: "#FFFFFF",
    padding: 0,
    border: "none",
    "&:disabled": {
      color: "rgba(222, 231, 250, 0.4)",
    },
  },
});
export default useStyles;
