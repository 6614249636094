import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import "./index.css";
import "./App.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoute } from "./components/PrivateRoute";
import { Container } from "@material-ui/core";
import AppHeader from "./components/AppHeader";
import Page404 from "./pages/404";

//auth
import SignIn from "./pages/employee/SignIn";
import ForgotPassword from "./pages/employee/ForgotPassword";
import ResetPassword from "./pages/employee/ResetPassword";
import ResetEmail from "./pages/employee/ResetEmail";
import AcceptInvite from "./pages/employee/AcceptInvite";
import SetPassword from "./pages/employee/SetPassword";

//feature
import Featured from "./pages/featured/Featured";
import ChangeTrailerFeatures from "./pages/featured/ChangeTrailerFeatures";

//customer
import CustomerList from "./pages/customer/CustomerList.jsx";

//licensee
import LicenseeAdd from "./pages/licensee/LicenseeAdd";
import LicenseeEdit from "./pages/licensee/LicenseeEdit";
import LicenseeList from "./pages/licensee/LicenseeList";

//sub page
import RatingStarUser from "./components/RatingStarUser";
import UpSellListInLicensee from "./pages/licensee_rentalitems/UpSellListInLicensee";
import NewDocumentListInLicensee from "./pages/licensee_rentalitems/NewDocumentListInLicensee";
import ItemDocumentOfLicensee from "./pages/licensee_rentalitems/ItemDocumentOfLicensee";
import EmployeeListInLicensee from "./pages/licensee_rentalitems/EmployeeListInLicensee";
import Identification from "./pages/licensee_rentalitems/Identification";
import EditTrailerItemInLicensee from "./pages/licensee_rentalitems/EditTrailerItemInLicensee";
import TrailerListInLicensee from "./pages/licensee_rentalitems/TrailerListInLicensee";
import AddTrailerLicensee from "./pages/licensee_rentalitems/AddTrailerLicensee";
import AddUpsellLicensee from "./pages/licensee_rentalitems/AddUpsellLicensee";
import TrailerServiceInLicensee from "./pages/licensee_rentalitems/TrailerServiceInLicensee";

// ADMIN USER
import AdminUserList from "./pages/admin_user/AdminUserList";
import AddOrEditAdminUser from "./pages/admin_user/AddOrEditAdminUser";

//rental
import TrailerList from "./pages/rental/TrailerList";
import TrailerAdd from "./pages/rental/TrailerAdd";
import TrailerEdit from "./pages/rental/TrailerEdit";
import UpsellList from "./pages/rental/UpsellList";
import UpsellAdd from "./pages/rental/UpsellAdd";
import UpsellEdit from "./pages/rental/UpsellEdit";
//transaction-history
import TransactionHistoryList from "./pages/transaction/TransactionHistoryList";
const routes = [
  // FEATURED
  {
    path: "/",
    component: Featured,
    exact: true,
  },
  {
    path: "/customers",
    component: CustomerList,
    exact: true,
  },
  {
    path: "/licensee/rating-star/:licenseeId",
    component: RatingStarUser,
    exact: true,
  },

  {
    path: "/licensee",
    component: LicenseeList,
    exact: true,
  },

  {
    path: "/licensee/:firstname/:licenseeId/employee/list",
    component: EmployeeListInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/trailer/edit/:trailerId",
    component: EditTrailerItemInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:firstname/:licenseeId/upsellitems",
    component: UpSellListInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:firstname/:licenseeId/trailers",
    component: TrailerListInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/trailers-service/:trailerId",
    component: TrailerServiceInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/docs",
    component: NewDocumentListInLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/insurance/identification",
    component: Identification,
    exact: true,
  },

  {
    path: "/licensee/:licenseeId/insurance/business-registration",
    component: ItemDocumentOfLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/insurance/business-insurance",
    component: ItemDocumentOfLicensee,
    exact: true,
  },
  {
    path: "/licensee/:licenseeId/insurance/proof-of-incorporation",
    component: ItemDocumentOfLicensee,
    exact: true,
  },
  {
    path: "/admin",
    component: AdminUserList,
    exact: true,
  },
  {
    path: "/admin/add",
    component: AddOrEditAdminUser,
    exact: true,
  },
  {
    path: "/admin/:userId",
    component: AddOrEditAdminUser,
    exact: true,
  },
  {
    path: "/rental/trailer",
    component: TrailerList,
    exact: true,
  },
  {
    path: "/rental/trailer/add",
    component: TrailerAdd,
    exact: true,
  },
  {
    path: "/rental/trailer/edit/:id",
    component: TrailerEdit,
    exact: true,
  },
  {
    path: "/rental/upsell",
    component: UpsellList,
    exact: true,
  },
  {
    path: "/rental/upsell/add",
    component: UpsellAdd,
    exact: true,
  },
  {
    path: "/rental/upsell/edit/:id",
    component: UpsellEdit,
    exact: true,
  },
  {
    path: "/transaction-history",
    component: TransactionHistoryList,
    exact: true,
  },
];
const mainContainerStyle = {
  marginTop: "55px",
  padding: "24px 60px 0",
  width: "100%",
  maxWidth: "100%",
  boxSizing: "border-box",
};
export default function App() {
  toast.configure({
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return (
    <Router>
        <Switch>
          <Route path="/signin" exact>
            <SignIn></SignIn>
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password/:id" exact>
            <ResetPassword />
          </Route>
          <Route path="/reset-email/:id" exact>
            <ResetEmail />
          </Route>
          <Route path="/accept-invite/:id">
            <AcceptInvite />
          </Route>
          <Route path="/set-password/:id" exact>
            <SetPassword />
          </Route>
          <PrivateRoute component={LicenseeAdd} path="/licensee/signup" exact />
          <PrivateRoute
            component={LicenseeEdit}
            path="/licensee/signup/:id"
            exact
          />
          <PrivateRoute
            component={AddTrailerLicensee}
            path="/licensee/:firstname/:licenseeId/trailer/add"
            exact
          />
          <PrivateRoute
            component={AddUpsellLicensee}
            path="/licensee/:firstname/:licenseeId/upsellitems/add"
            exact
          />
          <PrivateRoute
            component={ChangeTrailerFeatures}
            path="/featured-trailer/:featureId"
            exact
          />
          <Route path="/404">
            <Page404 />
          </Route>
          <Route>
            <AppHeader showMenu={true} />
            <Container maxWidth={false} style={mainContainerStyle}>
              <Switch>
                {routes.map((route, index) => (
                  <PrivateRoute {...route} key={index} />
                ))}
              </Switch>
            </Container>
          </Route>
        </Switch>
    </Router>
  );
}
