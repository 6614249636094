import React, { useEffect, useRef, useState } from "react"
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage"
import MaterialTable from "material-table"
import { useParams } from "react-router-dom"
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight"
import ButtonActionSubPage from "../../components/ButtonActionSubPage"
import DialogIdentification from "../../components/DialogIdentification"
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage"
import axios from "../../helpers/axios"

const optionalDataColumn = { 
  name: "Employee Photo",
  type: "photoOfEmployee"
}

const Identification = () => {
  const [ dataColumn, setDataColumn ] = useState(
    [
      {
        name: "Drivers License",
        type: "driversOfLicensee"
      },
      {
        name: "Licensee Photo",
        type: "photoOfLicensee"
      },
  ])
  const [ dataList, setDataList ] = useState({
    driversOfLicensee: [],
    photoOfLicensee: [],
  })
  const [ open, setOpen ] = useState(false)
  const typeOfDataList = useRef("driversOfLicensee")
  const paramId = useParams()
  const mockData = 
  [
    {
      field: "trailerType",
      render: (rowData) => {
        return (
          <div style={{ fontSize: 16, lineHeight: "19px" }}>
            {rowData.name}
          </div>
        )
      },
    },
    {
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage onClick={() => handleOpenDialog(rowData.type)}>
              View
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        )
      },
    },
  ]

  function handleOpenDialog(type) {
    typeOfDataList.current = type
    setOpen(!open)
  }

  function handleClose() {
    setOpen(false)
  }

  function returnHeaderDialog(type) {
    return dataColumn.find((item) => item.type === type).name
  }

  useEffect(() => {
    axios.get(`v1/admin/detaillicensee/${paramId.licenseeId}`).then((response) => {
      const { driversOfLicensee, photo, licenseeId } = response.data.licenseeUserObj
      setDataList({
        driversOfLicensee: [...driversOfLicensee],
        photoOfLicensee: [photo],
        ...( licenseeId?.additionalDocs?.data && { photoOfEmployee: [licenseeId?.additionalDocs]})
      })
      licenseeId?.additionalDocs?.data && setDataColumn(prev => [...prev, optionalDataColumn])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <WrapHeaderSubPage nameHeader={`Identification`}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <DialogIdentification 
        open={open}
        onClose={handleClose}
        data={{ 
          header: returnHeaderDialog(typeOfDataList.current),
          list: dataList[typeOfDataList.current]
        }}
      />
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={dataColumn}
        options={{
          header: false,
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          rowStyle: rowData => {
            // FIRST ELEMENT
            return rowData.tableData.id === 0 && { borderTop: "1px solid #24272d" }
          },
          actionsColumnIndex: -1,
        }}
      />
    </>
  )
}
export default Identification