import React, { useState, useEffect } from "react";
import { TextField, withStyles } from "@material-ui/core";
export const FieldFormAdd = withStyles({
  // ----OLDER STYLED----//
  root: {
    borderRadius: "7px",
    marginBottom: "24px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      height: "51px",
      "&.Mui-disabled": {
        opacity: 0.7,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9297a4",
        }
      }
    },
    "& .MuiInputLabel-outlined": {
      color: "#9297a4",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& .MuiSelect-iconOutlined": {
      right: "30px",
    },
  },
})((props) => {
  return <TextField {...props} />
})
