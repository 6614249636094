import React from "react";
import { render } from "react-dom";
import App from "./App";

import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import OverLayProvider from "./context/OverlayProvider";

render(
  <ThemeProvider theme={Themes.default}>
    <CssBaseline />
    <OverLayProvider>
      <App />
    </OverLayProvider>
  </ThemeProvider>,
  document.getElementById("t2y-app")
);
