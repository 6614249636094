import React from "react"
import { Typography, Box } from "@material-ui/core"
import style from "./style"
const AlertMissingDocument = ({
  marginTop = "80px", 
  marginBottom = "80px", 
  header, 
  contact,
  title
}) => {
  return (
    <Box {...style.container} marginTop={marginTop} marginBottom={marginBottom}>
      <Box width="500px" textAlign="center">
        <Typography style={style.header}>
          {header}
        </Typography>
        <Typography style= {style.title} >
          {title}
        </Typography>
        <Typography variant="h6" color="primary" style={style.phone}>
          {contact}
        </Typography>
      </Box>
    </Box>
  )
}
export default AlertMissingDocument