import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";
import useCssListItem from "./style"; 

const ButtonNavLink = ({ name, link }) => {
  const cssListItem = useCssListItem();
  return (
    <div className={cssListItem.root}>
      <ListItem
        button
        disableTouchRipple
        key={name}
        to={link}
        component={NavLink}
        classes={{
          root: cssListItem.link,
          gutters: cssListItem.gutters,
          button: cssListItem.button,
        }}
        exact={ link === "/" ? true : false }
      >
        <ListItemText>{name}</ListItemText>
      </ListItem>
    </div>
  );
};

export default ButtonNavLink;
