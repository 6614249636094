import React from "react";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { Typography } from "@material-ui/core";
import "./style.scss";
const LayoutAddOrEdit = ({ titleDetail, children }) => {
  return (
    <div className="layout__add">
      <div className="layout__left">
        <Logo />
        <div className="titleDetail">
          <Typography variant="h1" component="h1">
            {titleDetail}
          </Typography>
        </div>
      </div>
      <div className="layout__right">{children}</div>
    </div>
  );
};

export default LayoutAddOrEdit;
