import React, { useState, useEffect } from "react";
import { ButtonCircle } from "../../components/ButtomCircle";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit/index";
import SwitchCus from "../../components/SwitchCus";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import AlertPopup from "../../components/AlertPopup";
import {
  Typography,
  TextField,
  makeStyles,
  Box,
  MobileStepper,
  Button,
  IconButton,
} from "@material-ui/core";
import { common } from "../../constants/common";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Subtract } from "../../img/subtract.svg";
import TextFieldMask from "../../components/TextFieldMask/index";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { CustomTextField } from "../../components/SelectField";
import { toast } from "react-toastify";
import SearchPlace from "../../components/SearchPlace/SearchPlace";
import {
  isMobilePhone,
  isEmail,
  isFullDate,
  isTime,
  isImgFile,
  checkTimeStartEnd,
} from "../../helpers/verify";
import ItemAddTrailer from "../../components/ItemAddTrailer";
import { formatMobileNumber } from "../../helpers/format";
import moment from "moment";
const listValidate = [
  yup.object({
    firstName: yup.string().required(" "),
    lastName: yup.string().required(" "),
    email: yup.string().required(" ").email(" "),
    phone: yup.string().required(" "),
    businessType: yup.string().required(" "),
  }),
  yup.object({
    licenseNumber: yup.string().min(11, " ").required(" "),
    expiryDate: yup.string().min(10, " ").required(" "),
    state: yup.string().required(" "),
  }),
  yup.object({
    registrationExpiry: yup.string().min(10, " ").required(" "),
    insuranceExpiry: yup.string().min(10, " ").required(" "),
  }),
  yup.object({
    aptEtc: yup.string(),
    radius: yup.number().required(" "),
  }),
  yup.object({}),
];

const LicenseeAdd = () => {
  const classes = useStyles();
  let history = useHistory();
  const [activeStep, setActiveStep] = React.useState(1);
  const [titleDetail, setTitleDetail] = useState(detailsStep[0]);
  const [trailerList, setTrailerList] = useState([]);
  const [trailerAddList, setTrailerAddList] = useState([]);
  const [upsellList, setUpsellList] = useState([]);
  const [upsellAddList, setUpsellAddList] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [photoOfCus, setPhotoOfCus] = useState({
    status: true,
    file: null,
  });

  const [registrationExpiry, setRegistrationExpiry] = useState({
    status: true,
    file: null,
  });
  const [insuranceExpiry, setInsuranceExpiry] = useState({
    status: true,
    file: null,
  });
  const [businessLogo, setBusinessLogo] = useState({
    status: true,
    file: null,
  });
  const [proof, setProof] = useState({
    status: true,
    file: null,
  });
  const [frontDriver, setFrontDriver] = useState({
    status: true,
    file: null,
  });
  const [backDriver, setBackDriver] = useState({
    status: true,
    file: null,
  });
  const [addDocs, setAddDocs] = useState({
    status: true,
    file: null,
  });
  const [location, setLocation] = useState({ name: "" });
  const [errorLocation, setErrorLocation] = useState(false);
  const [licensee, setLicensee] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessType: "",
    licenseNumber: "",
    expiryDate: "",
    state: "",
    aptEtc: "",
    city: "",
    suburb: "",
    stateWork: "",
    postCode: "",
    radius: "",
    registrationExpiry: "",
    insuranceExpiry: "",
  });
  const [email, setEmail] = useState(licensee.email);
  const [workingHours, setWorkingHours] = useState([
    {
      id: 1,
      day: "Sunday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 2,
      day: "Monday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 3,
      day: "Tuesday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 4,
      day: "Wednesday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 5,
      day: "Thursday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 6,
      day: "Friday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 7,
      day: "Saturday",
      isOpen: true,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
  ]);

  useEffect(() => {
    axios.get("/v1/admin/get-all-upsellitems").then((response) => {
      return setUpsellList(
        response.data.dataObj.map((item) => {
          return { quantity: 0, upsell: item };
        })
      );
    });
    axios.get("/v1/admin/get-all-trailers").then((response) => {
      return setTrailerList(
        response.data.dataObj.map((trailer) => {
          return { quantity: 0, trailer: trailer };
        })
      );
    });
  }, []);

  const validateWorkingHour = (workingHoursState) => {
    const workingHours = [...workingHoursState];
    for (let item of workingHours) {
      if (item.isOpen) {
        if (!isTime(item.timeOpen) || !isTime(item.timeClose)) {
          if (!isTime(item.timeOpen)) {
            item.errorOpen = true;
          }
          if (!isTime(item.timeClose)) {
            item.errorClose = true;
          }
          setWorkingHours(workingHours);
          return false;
        }
        if (!checkTimeStartEnd(item.timeOpen, item.timeClose)) {
          item.errorClose = true;
          return false;
        }
      }
    }
    return workingHours.some((item) => item.isOpen);
  };

  const checkListTrailer = () => {
    const check = trailerList.some((trailer) => {
      return trailer.quantity > 0;
    });
    if (!check) {
      setOpenAlert(true);
      return false;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: licensee,
    enableReinitialize: true,
    validationSchema: listValidate[activeStep - 1],
    onSubmit: (value) => {
      if (activeStep === 1) {
        if (!isMobilePhone(value.phone)) {
          return false;
        }
        if (!photoOfCus.file) {
          return setPhotoOfCus({ ...photoOfCus, status: false });
        } else {
          setPhotoOfCus({ ...photoOfCus, status: true });
        }
        if (value.businessType === "company" && !businessLogo.file) {
          return setBusinessLogo({ ...businessLogo, status: false });
        } else {
          setBusinessLogo({ ...businessLogo, status: true });
        }
      }
      if (activeStep === 2) {
        if (!isFullDate(value.expiryDate)) {
          return false;
        }
        if (!frontDriver.file) {
          return setFrontDriver({ ...frontDriver, status: false });
        } else {
          setFrontDriver({ ...frontDriver, status: true });
        }
        if (!backDriver.file) {
          return setBackDriver({ ...backDriver, status: false });
        } else {
          setBackDriver({ ...backDriver, status: true });
        }
      }
      if (activeStep === 3) {
        if (
          !isFullDate(value.registrationExpiry) ||
          !isFullDate(value.insuranceExpiry)
        ) {
          return false;
        }
        // if (!registrationExpiry.file) {
        //   return setRegistrationExpiry({
        //     ...registrationExpiry,
        //     status: false,
        //   });
        // } else {
        //   setRegistrationExpiry({ ...registrationExpiry, status: true });
        // }
        // if (!insuranceExpiry.file) {
        //   return setInsuranceExpiry({ ...insuranceExpiry, status: false });
        // } else {
        //   setInsuranceExpiry({ ...insuranceExpiry, status: true });
        // }
      }
      if (activeStep === 4 && !location.name) {
        return setErrorLocation(true);
      }
      if (activeStep === 5) {
        if (!validateWorkingHour(workingHours)) {
          return false;
        }
      }
      if (activeStep === 6) {
        if (!checkListTrailer()) {
          return false;
        }
      }

      setLicensee({ ...licensee, ...value });
      setEmail(value.email);

      handleNext();
    },
  });

  const handleClickExit = () => {
    history.push("/licensee");
  };
  const handleChangeQuantity = (id, method, type) => {
    if (type === "upsell") {
      const temp = [...upsellList];
      for (let item of temp) {
        if (item.upsell._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }
      let temp2 = [];
      for (let item of temp.filter((trailer) => trailer.quantity > 0)) {
        for (let i = 0; i < item.quantity; i++) {
          temp2.push({
            upsellItemId: item.upsell._id,
          });
        }
      }
      setUpsellAddList(temp2);
      setUpsellList(temp);
    }
    if (type === "trailer") {
      const temp = [...trailerList];
      for (let item of temp) {
        if (item.trailer._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }
      let temp2 = [];
      for (let item of temp.filter((trailer) => trailer.quantity > 0)) {
        for (let i = 0; i < item.quantity; i++) {
          temp2.push({
            trailerId: item.trailer._id,
            registration: null,
            expiryRegistration: null,
          });
        }
      }
      setTrailerAddList(temp2);
      setTrailerList(temp);
    }
    setOpenAlert(false);
  };
  const handleNext = () => {
    if (activeStep < 8) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setTitleDetail(detailsStep[activeStep]);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTitleDetail(detailsStep[activeStep - 2]);
  };
  const handleChangeWorking = (id) => {
    const temp = workingHours.map((item) => {
      if (item.id === id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return item;
      }
    });
    setWorkingHours(temp);
  };

  const handleChangeWorkingHour = (e, id, type) => {
    const temp = workingHours.map((item) => {
      if (item.id === id) {
        item.errorClose = false;
        item.errorOpen = false;
        if (type === "open") {
          if (isTime(e.target.value)) {
            return { ...item, timeOpen: e.target.value, errorOpen: false };
          }
          return { ...item, timeOpen: e.target.value, errorOpen: true };
        } else {
          if (isTime(e.target.value)) {
            return { ...item, timeClose: e.target.value, errorClose: false };
          }
          return { ...item, timeClose: e.target.value, errorClose: true };
        }
      } else {
        return item;
      }
    });
    setWorkingHours(temp);
  };
  const renderForm = (activeStep) => {
    switch (activeStep) {
      case 1: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="form-input">
              <FieldFormAdd
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="phone"
                name="phone"
                label="Phone Number"
                variant="outlined"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={
                  (formik.touched.phone && Boolean(formik.errors.phone)) ||
                  !isMobilePhone(formik.values.phone)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <CustomTextField
                name="businessType"
                id="businessType"
                label="Business type"
                value={formik.values.businessType}
                onChange={formik.handleChange}
                error={
                  formik.touched.businessType &&
                  Boolean(formik.errors.businessType)
                }
                options={common.businessType}
                classMenuItem={classes.menuItem}
              />
              <div className="main-media">
                <input
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  className={classes.input}
                  id="contained-button-file1"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setPhotoOfCus({ status: true, file: e.target.files[0] });
                    } else if (photoOfCus.file) {
                      setPhotoOfCus({ ...photoOfCus, status: true });
                    } else {
                      setPhotoOfCus({ file: null, status: false });
                    }
                  }}
                />
                <label htmlFor="contained-button-file1">
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${classes.btnMedia} ${
                      !photoOfCus.status ? classes.btnError : null
                    }`}
                  >
                    Add Photo of Licensee
                  </ButtonCircle>
                  <div className="file-name">
                    {photoOfCus.file?.name.slice(-20)}
                  </div>
                </label>

                <input
                  className={classes.input}
                  id="contained-button-file2"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setBusinessLogo({
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (businessLogo.file) {
                      setBusinessLogo({ ...businessLogo, status: true });
                    } else {
                      setBusinessLogo({ file: null, status: false });
                    }
                  }}
                />
                <label htmlFor="contained-button-file2">
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${classes.btnMedia} ${
                      !businessLogo.status ? classes.btnError : null
                    }`}
                  >
                    Add Business Logo
                  </ButtonCircle>
                  <div className="file-name">
                    {businessLogo.file?.name.slice(-20)}
                  </div>
                </label>
              </div>
            </div>
          </>
        );
      }
      case 2: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="form-input">
              <TextFieldMask
                formatChars={common.formatNumber}
                mask="999 999 999"
                inputProps={{
                  maxLength: 250,
                }}
                value={formik.values.licenseNumber}
                onChange={formik.handleChange}
                id="licenseNumber"
                name="licenseNumber"
                label="Driver license number"
              >
                {(inputProps) => (
                  <FieldFormAdd
                    fullWidth
                    variant="outlined"
                    error={
                      formik.touched.licenseNumber &&
                      Boolean(formik.errors.licenseNumber)
                    }
                    {...inputProps}
                  />
                )}
              </TextFieldMask>
              <TextFieldMask
                formatChars={common.formatDate}
                mask="32/12/2222"
                value={formik.values.expiryDate}
                onChange={formik.handleChange}
                error={
                  (formik.touched.expiryDate &&
                    Boolean(formik.errors.expiryDate)) ||
                  !isFullDate(formik.values.expiryDate)
                }
                inputProps={{
                  maxLength: 250,
                }}
                id="expiryDate"
                name="expiryDate"
                label="Expiry date"
              >
                {(inputProps) => (
                  <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
                )}
              </TextFieldMask>
              <CustomTextField
                name="state"
                id="state"
                label="State"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                options={common.states}
                classMenuItem={classes.menuItem}
              />
              <div className="main-media">
                <input
                  className={classes.input}
                  id="contained-button-file5"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setFrontDriver({
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (frontDriver.file) {
                      setFrontDriver({ ...frontDriver, status: true });
                    } else {
                      setFrontDriver({ file: null, status: false });
                    }                    
                  }}
                />
                <label
                  htmlFor="contained-button-file5"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${!frontDriver.status ? classes.btnError : null}`}
                  >
                    Front of drivers license
                  </ButtonCircle>
                  <div className="file-name">
                    {frontDriver.file?.name.slice(-20)}
                  </div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file4"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setBackDriver({
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (backDriver.file) {
                      setBackDriver({ ...backDriver, status: true });
                    } else {
                      setBackDriver({ file: null, status: false });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file4"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${!backDriver.status ? classes.btnError : null}`}
                  >
                    Back of drivers license
                  </ButtonCircle>
                  <div className="file-name">
                    {backDriver.file?.name.slice(-20)}
                  </div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file2"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setProof({
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (proof.file) {
                      setProof({ ...proof, status: true });
                    } else {
                      setProof({ file: null, status: false });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file2"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${!proof.status ? classes.btnError : null}`}
                  >
                    Proof of incorporation
                  </ButtonCircle>
                  <div className="file-name">{proof.file?.name.slice(-20)}</div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file3"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (isImgFile(e.target.files[0])) {
                      setAddDocs({
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (addDocs.file) {
                      setAddDocs({ ...addDocs, status: true });
                    } else {
                      setAddDocs({ file: null, status: false });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file3"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Additional Docs
                  </ButtonCircle>
                  <div className="file-name">
                    {addDocs.file?.name.slice(-20)}
                  </div>
                </label>
              </div>
            </div>
          </>
        );
      }
      case 3: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>

            <TextFieldMask
              formatChars={common.formatDate}
              mask="32/12/2222"
              value={formik.values.registrationExpiry}
              onChange={formik.handleChange}
              error={
                (formik.touched.registrationExpiry &&
                  Boolean(formik.errors.registrationExpiry)) ||
                !isFullDate(formik.values.registrationExpiry)
              }
              inputProps={{
                maxLength: 250,
              }}
              id="registrationExpiry"
              name="registrationExpiry"
              label="Registration Expiry"
            >
              {(inputProps) => (
                <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
              )}
            </TextFieldMask>
            <TextFieldMask
              formatChars={common.formatDate}
              mask="32/12/2222"
              id="insuranceExpiry"
              name="insuranceExpiry"
              label="Insurance Expiry"
              variant="outlined"
              value={formik.values.insuranceExpiry}
              onChange={formik.handleChange}
              error={
                (formik.touched.insuranceExpiry &&
                  Boolean(formik.errors.insuranceExpiry)) ||
                !isFullDate(formik.values.insuranceExpiry)
              }
              inputProps={{
                maxLength: 250,
              }}
            >
              {(inputProps) => (
                <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
              )}
            </TextFieldMask>

            <div className="main-media">
              <input
                accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                className={classes.input}
                id="contained-button-file10"
                multiple
                type="file"
                onChange={(e) => {
                  if (isImgFile(e.target.files[0])) {
                    setRegistrationExpiry({
                      status: true,
                      file: e.target.files[0],
                    });
                  } else if (registrationExpiry.file) {
                    setRegistrationExpiry({
                      ...registrationExpiry,
                      status: true,
                    });
                  } else {
                    setRegistrationExpiry({ file: null, status: false });
                  }
                }}
              />
              <label htmlFor="contained-button-file10">
                <ButtonCircle
                  variant="contained"
                  color="primary"
                  component="span"
                  className={`${classes.btnMedia} ${
                    !registrationExpiry.status ? classes.btnError : null
                  }`}
                >
                  Business registration
                </ButtonCircle>
                <div className="file-name">
                  {registrationExpiry.file?.name.slice(-20)}
                </div>
              </label>

              <input
                className={classes.input}
                id="contained-button-file20"
                multiple
                type="file"
                accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                onChange={(e) => {
                  if (isImgFile(e.target.files[0])) {
                    setInsuranceExpiry({
                      status: true,
                      file: e.target.files[0],
                    });
                  } else if (insuranceExpiry.file) {
                    setInsuranceExpiry({ ...insuranceExpiry, status: true });
                  } else {
                    setInsuranceExpiry({ file: null, status: false });
                  }
                }}
              />
              <label htmlFor="contained-button-file20">
                <ButtonCircle
                  variant="contained"
                  color="primary"
                  component="span"
                  className={`${classes.btnMedia} ${
                    !insuranceExpiry.status ? classes.btnError : null
                  }`}
                >
                  Business insurance
                </ButtonCircle>
                <div className="file-name">
                  {insuranceExpiry.file?.name.slice(-20)}
                </div>
              </label>
            </div>
          </>
        );
      }
      case 4: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <input type="hidden" autoComplete="chrome-off" />
            <SearchPlace
              title="Street address"
              location={location}
              setLocation={setLocation}
              errorLocation={errorLocation}
              setErrorLocation={setErrorLocation}
              licensee={licensee}
              setLicensee={setLicensee}
            />
            <FieldFormAdd
              fullWidth
              id="aptEtc"
              name="aptEtc"
              label="Apt, suite, etc (Optional)"
              variant="outlined"
              value={formik.values.aptEtc}
              onChange={formik.handleChange}
              error={formik.touched.aptEtc && Boolean(formik.errors.aptEtc)}
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              disabled  
              id="suburb"
              name="suburb"
              label="Suburb"
              variant="outlined"
              autoComplete="off"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              disabled  
              id="city"
              name="city"
              label="City"
              variant="outlined"
              value={formik.values.city}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 250,
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldFormAdd
                disabled  
                id="stateWork"
                name="stateWork"
                label="State"
                variant="outlined"
                className={classes.input50}
                value={formik.values.stateWork}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                disabled  
                id="postCode"
                name="postCode"
                label="Post Code"
                variant="outlined"
                className={classes.input50}
                value={formik.values.postCode}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 250,
                }}
              />
            </div>
            <FieldFormAdd
              fullWidth
              id="radius"
              name="radius"
              label="Radius for Trailer Drop off (Km)"
              variant="outlined"
              value={formik.values.radius}
              onChange={formik.handleChange}
              error={formik.touched.radius && Boolean(formik.errors.radius)}
              inputProps={{
                maxLength: 250,
              }}
            />
          </>
        );
      }
      case 5: {
        return (
          <>
            <Typography variant="h3" className={classes.titleWorking}>
              {titleDetail.subTitle}
            </Typography>
            {workingHours.map((item, index) => {
              return (
                <div key={item.id} className="selectWorkingHour">
                  <div>
                    <Typography variant="h4" className={classes.titleDay}>
                      {item.day}
                    </Typography>
                    <SwitchCus
                      checked={item.isOpen}
                      onChange={() => handleChangeWorking(item.id)}
                      text="Open"
                    />
                  </div>

                  <div
                    className={
                      item.isOpen ? classes.formTimeHour : classes.hidden
                    }
                  >
                    <TextFieldMask
                      formatChars={common.formatTime}
                      mask="12:34"
                      placeholder="Opens at"
                      className={classes.inputTime}
                      value={item.timeOpen}
                      onChange={(e) =>
                        handleChangeWorkingHour(e, item.id, "open")
                      }
                      error={item.errorOpen}
                    >
                      {(inputProps) => (
                        <TextField variant="outlined" {...inputProps} />
                      )}
                    </TextFieldMask>

                    <Subtract className={classes.subtract} />
                    <TextFieldMask
                      formatChars={common.formatTime}
                      mask="12:34"
                      placeholder="Closes at"
                      className={classes.inputTime}
                      value={item.timeClose}
                      onChange={(e) =>
                        handleChangeWorkingHour(e, item.id, "close")
                      }
                      error={item.errorClose}
                    >
                      {(inputProps) => (
                        <TextField variant="outlined" {...inputProps} />
                      )}
                    </TextFieldMask>
                  </div>
                </div>
              );
            })}
          </>
        );
      }

      case 6: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>

            <div className="list-add-trailer">
              {trailerList.map((item, index) => (
                <ItemAddTrailer
                  key={index}
                  name={item.trailer.name}
                  urlImage={item.trailer.photos[0]?.url}
                  quantity={item.quantity}
                  handleSubQuantity={() =>
                    handleChangeQuantity(
                      item.trailer._id,
                      "subtract",
                      "trailer"
                    )
                  }
                  handleAddQuantity={() =>
                    handleChangeQuantity(item.trailer._id, "add", "trailer")
                  }
                />
              ))}
            </div>
          </>
        );
      }
      case 7: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="list-add-trailer">
              {upsellList.map((item, index) => (
                <ItemAddTrailer
                  key={index}
                  name={item.upsell.name}
                  urlImage={item.upsell.photos[0]?.url}
                  quantity={item.quantity}
                  handleSubQuantity={() =>
                    handleChangeQuantity(item.upsell._id, "subtract", "upsell")
                  }
                  handleAddQuantity={() =>
                    handleChangeQuantity(item.upsell._id, "add", "upsell")
                  }
                />
              ))}
            </div>
          </>
        );
      }
    }
  };
  const [showLoading, hideLoading] = useLoading();
  const handleSignupLicensee = async () => {
    if (isEmail(email)) {
      return false;
    }

    showLoading();
    try {
      const workingHoursTemp = workingHours.map((item) => {
        return {
          day: item.day.toLowerCase(),
          startTime: item.timeOpen,
          closeTime: item.timeClose,
          isOpen: item.isOpen,
        };
      });

      const formData = new FormData();
      formData.append("firstname", licensee.firstName);
      formData.append("lastname", licensee.lastName);
      formData.append("mobile", formatMobileNumber(licensee.phone));
      formData.append("businessType", licensee.businessType);
      formData.append("driverNumber", licensee.licenseNumber.replace(/ /g, ""));
      formData.append("expiryDate", licensee.expiryDate);
      formData.append("state", licensee.state);
      formData.append("streetAddress", location.name);
      formData.append("postCode", licensee.postCode);
      formData.append("radius", licensee.radius);
      formData.append("city", licensee.city);
      formData.append("suburb", licensee.suburb);
      formData.append(
        "businessRegistrationExpiry",
        licensee.registrationExpiry
      );
      formData.append("businessInsuranceExpiry", licensee.insuranceExpiry);
      if (licensee.aptEtc) {
        formData.append("apt", licensee.aptEtc);
      }

      formData.append("stateAddress", licensee.stateWork);

      for (let item of workingHoursTemp) {
        formData.append("workingDays[]", JSON.stringify(item));
      }

      for (let item of trailerAddList) {
        formData.append("trailers[]", JSON.stringify(item));
      }

      for (let item of upsellAddList) {
        formData.append("upsellItems[]", JSON.stringify(item));
      }

      formData.append("inviteEmail", email);
      formData.append("coordinates[]", location.geometry.location.lng);
      formData.append("coordinates[]", location.geometry.location.lat);

      if (photoOfCus.status) {
        formData.append(
          "photoCustomer",
          photoOfCus.file,
          photoOfCus.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      if (insuranceExpiry.status && insuranceExpiry.file) {
        formData.append(
          "businessInsurance",
          insuranceExpiry.file,
          `Business_Insurance_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (registrationExpiry.status && registrationExpiry.file) {
        formData.append(
          "businessRegistration",
          registrationExpiry.file,
          `Business_Registration_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (businessLogo.status && businessLogo.file) {
        formData.append(
          "businessLogo",
          businessLogo.file,
          businessLogo.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }

      if (backDriver.status && backDriver.file) {
        formData.append(
          "driversOfLicensee",
          backDriver.file,
          backDriver.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      if (frontDriver.status && frontDriver.file) {
        formData.append(
          "driversOfLicensee",
          frontDriver.file,
          frontDriver.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      // if (driversLicense.status) {
      //   formData.append(
      //     "driversOfLicensee",
      //     driversLicense.files[0],
      //     driversLicense.files[0].name.replaceAll(" ", "").replaceAll("/", "")
      //   );
      //   formData.append(
      //     "driversOfLicensee",
      //     driversLicense.files[1],
      //     driversLicense.files[1].name.replaceAll(" ", "").replaceAll("/", "")
      //   );
      // }
      if (proof.status && proof.file) {
        formData.append(
          "proofOfIncorporation",
          proof.file,
          `Proof_Of_Incorporation_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (addDocs.file) {
        formData.append(
          "additionalDocs",
          addDocs.file,
          addDocs.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      const res = await axios.post("/v1/admin/licensee", formData);
      toast.success(res?.data?.message);
      hideLoading();
      history.push("/licensee");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <LayoutAddOrEdit titleDetail={titleDetail.title}>
      <div className="btn-exit">
        <ButtonCircle variant="contained" onClick={handleClickExit}>
          Exit
        </ButtonCircle>
        {activeStep === 8 && (
          <ButtonCircle variant="contained" onClick={handleBack}>
            Back
          </ButtonCircle>
        )}
      </div>
      <form onSubmit={formik.handleSubmit} autoComplete="chrome-off">
        {activeStep < 8 && (
          <div className="form-main">
            {renderForm(activeStep)}
            <div className="alert">
              <AlertPopup
                title="Please select at least one trailer from the list"
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
              />
            </div>

            <div className="footter-process">
              <div className="step-process">
                <MobileStepper
                  variant="progress"
                  steps={8}
                  position="static"
                  activeStep={activeStep}
                  className={classes.process}
                />
              </div>
              <div className="btn-process">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBack}
                  className={
                    activeStep === 1 ? classes.hidden : classes.btnStep
                  }
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnStep}
                  type="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
      </form>
      {activeStep === 8 && (
        <div className="layout-invite">
          <Typography variant="h3" className="title-send-invite">
            {titleDetail.subTitle}
          </Typography>
          <div className="form-invite">
            <Typography variant="h4" className="title-send-invite">
              {email}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSignupLicensee}
            >
              Send Invite
            </Button>
          </div>
        </div>
      )}
    </LayoutAddOrEdit>
  );
};

export default LicenseeAdd;

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: "40px",
    padding: "12px 16px",
    fontSize: "14px",
    boxShadow: "inset 0px -1px 0px #ECF1FC",
    "&:last-child": {
      boxShadow: "none",
    },
  },
  btnStep: {
    width: "97px",
  },
  input50: {
    width: "calc( (50vw - 188px ) / 2 - 8px)",
    "& .MuiSelect-iconOutlined": {
      right: "15px",
    },
  },

  subtract: {
    fontSize: "18px",
    width: "18px",
    height: "2px",
  },
  btnMedia: {
    width: "187px",
    marginRight: "18px",
  },
  btnError: {
    backgroundColor: "#FF0000",
  },
  btnMediaLicense: {
    marginRight: "7px",
  },
  titleWorking: {
    marginBottom: "45px!important",
  },

  titleDay: {
    width: "126px",
    marginRight: "24px",
  },
  inputTime: {
    margin: "0 12px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
    },
    "input::placeholder": {
      color: "white",
    },
  },
  formTimeHour: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
  },
  listAddTrailer: {
    width: "100%",
    marginTop: "-8px",
    height: "calc( 100vh - 234px)",
  },
  itemAddTrailer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "92px",
    borderTop: "2px solid #535f78",
  },
  imageTrailer: {
    marginLeft: 8,
  },
  nameTrainer: {
    flex: 1,
    marginLeft: 16,
  },
  countUpsellItem: {
    padding: 6,
    "&.MuiIconButton-root.Mui-disabled": {
      color: "rgba(255,255,255,0.5) !important",
    },
  },
  countUpsell: {
    padding: 6,
  },
  iconCount: { fontSize: 28 },
  btnAddTrailer: {
    width: "83px",
  },
  hidden: {
    display: "none",
  },
  input: {
    display: "none",
  },
  process: {
    "&.MuiMobileStepper-root": {
      padding: "0",
    },
    "& .MuiMobileStepper-progress": {
      width: "100%",
      padding: 0,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#c4c4c4",
    },
  },
}));
const detailsStep = [
  {
    step: 1,
    title: "Add our Licensee's personal details",
    subTitle: "Personal Details",
  },
  {
    step: 2,
    title: "Add our Licensee's Identification",
    subTitle: "Drivers License and Proof of incorporation",
  },
  {
    step: 3,
    title: "Add our Licensee’s Business Docs",
    subTitle: "Business Documents",
  },
  {
    step: 4,
    title: "Add our Licensee’s work address",
    subTitle: "Work address",
  },
  {
    step: 5,
    title: "Add our Licensee’s working hours",
    subTitle: "Working days and Hours",
  },

  {
    step: 6,
    title: "Add our Licensee’s trailers",
    subTitle: "Select trailers",
  },
  {
    step: 7,
    title: "Add our Licensee’s upsell items",
    subTitle: "Select upsell items",
  },
  {
    step: 8,
    title: "Send invite to the Licensee App",
    subTitle: "Send invite to the Licensee",
  },
];
