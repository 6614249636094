import { makeStyles } from "@material-ui/styles"
const SIDE_PADDING_CONTAINER_DIALOG = "44px"
const MARGIN_TOP_CONTAINER_DIALOG = "21px"
const MARGIN_BOTTOM_CONTAINER_DIALOG = "37px"
const MARGIN_TOP_SCROLL_CONTAINER = "74px"
const MARGIN_AXIS_Y_DEFAULT_DIALOG = "64px"
export const useDialogTrailerServicesStyle = makeStyles({
  containerContentDialog: {
    margin: `
      ${MARGIN_TOP_CONTAINER_DIALOG} 
      ${SIDE_PADDING_CONTAINER_DIALOG} 
      ${MARGIN_BOTTOM_CONTAINER_DIALOG} 
      ${SIDE_PADDING_CONTAINER_DIALOG}
    `,
    width: `calc(432px - 2*${SIDE_PADDING_CONTAINER_DIALOG})`,
    boxSizing: "content-box",
    height: "100%",
    overflowY: "hidden"
  },

  scrollContaierContentDialog: {
    marginTop: `${MARGIN_TOP_SCROLL_CONTAINER}`,
    maxHeight: `
      calc(100vh 
      - ${MARGIN_TOP_SCROLL_CONTAINER}
      - ${MARGIN_BOTTOM_CONTAINER_DIALOG}
      - ${MARGIN_TOP_CONTAINER_DIALOG}
      - ${MARGIN_AXIS_Y_DEFAULT_DIALOG}
      )`,
    overflowY: "scroll",
    boxSizing: "border-box",
  },
  
  fixedHeaderDialog: {
    position: "fixed",
    paddingTop: "31px",
    paddingBottom: "22px",
    width: "inherit",
    boxSizing: "border-box",
  },

  iconCloseDialog: {
    position: "absolute",
    cursor: "pointer",
    right: "-14px",
    top: 0,
    zIndex: "100"
  },

  headerContentDialog: {
    fontSize: "18px",
    lineHeight: "21.48px",
    fontWeight: 700,
  },

  itemTittle: {
    lineHeight: "19px",
    height: "19px",
  },

  itemContent: {
    padding: "16px 19px 16px 0",
    boxSizing:"border-box",
    display: "flex",
    justifyContent: "space-between",
  },

  borderBottom: {
    borderBottom: "1px solid rgba(83, 95, 120, 0.3)"
  },

  borderTop: {
    borderTop: "1px solid rgba(83, 95, 120, 0.3)"
  }
}) 
export const useCheckBoxItemTrailerServicesStyle = makeStyles({
  root: {
    width: "18px",
    height: "18px",
    "& .MuiIconButton-label": {
      width: "inherit",
      height: "inherit"
    }
  },
  colorPrimary: {
    "&.Mui-checked": {
      color: "#44D62C"
    }
  },
})