import React, { useState, Fragment, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ImageIcon from "@material-ui/icons/Image";
import CreateIcon from "@material-ui/icons/Create";
import {
  Button,
  Avatar,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as Delete } from "../../img/delete.svg";
import "../../App.scss";
import axios from "../../helpers/axios";
import SearchAndFilter from "../../components/SearchAndFilter";
import CustomPagination from "../../components/Pagination";
import ButtonView from "../../components/ButtonView";
import { useLoading } from "../../hooks/useLoading";
import ButtonActionListLicensee from "../../components/ButtonActionListLicensee";
import DialogChangeStatus from "../../components/DialogChangeStatus";

export default function LicenseeList() {
  const classes = useStyles();
  let history = useHistory();

  const [showLoading, hideLoading] = useLoading();
  const [searchFilter, setSearchFilter] = useState([
    {
      star4: false,
      belowStar4: false,
    },
    { active: false, deactivated: false },
    { verified: false, unverified: false },
    { search: "" },
  ]);

  const [listLicensee, setListLicensee] = useState([]);

  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  const [linkGetLicense, setLinkGetLicensee] = useState(
    `/v1/admin/licensee?count=${pagination.count}&&skip=0&&filters=&&verified=&&accountStatus=&&ratingStar=`
  );

  useEffect(() => {
    const temp = {
      search: "",
      verified: "",
      accountStatus: "",
      ratingStar: "",
    };
    if (searchFilter[0].star4 === searchFilter[0].belowStar4) {
      temp.ratingStar = "";
    } else if (searchFilter[0].star4) {
      temp.ratingStar = "above";
    } else if (searchFilter[0].belowStar4) {
      temp.ratingStar = "below";
    }
    if (searchFilter[1].active === searchFilter[1].deactivated) {
      temp.accountStatus = "";
    } else if (searchFilter[1].active) {
      temp.accountStatus = true;
    } else if (searchFilter[1].deactivated) {
      temp.accountStatus = false;
    }
    if (searchFilter[2].verified === searchFilter[2].unverified) {
      temp.verified = "";
    } else if (searchFilter[2].verified) {
      temp.verified = true;
    } else if (searchFilter[2].unverified) {
      temp.verified = false;
    }
    temp.search = searchFilter[3].search;
    let query = `/v1/admin/licensee?count=${pagination.count}&&skip=${pagination.skip}&&filters=${temp.search}&&verified=${temp.verified}&&accountStatus=${temp.accountStatus}&&ratingStar=${temp.ratingStar}`;
    setLinkGetLicensee(query);
  }, [searchFilter, pagination]);

  useEffect(() => {
    showLoading();
    axios
      .get(linkGetLicense)
      .then((response) => {
        hideLoading();
        setPagination({
          ...pagination,
          totalCount: response.data.licenseeList[0]?.count[0]?.totalCount
            ? response.data.licenseeList[0]?.count[0]?.totalCount
            : null,
        });
        return setListLicensee(response.data.licenseeList[0].licenseeData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [linkGetLicense]);

  const formatContacts = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{3})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3] + " " + match[4];
    }
    return null;
  };
  //-------------------------------------------------------------------------
  // REF FOR TABLE LIST LICENSEE
  const tableRef = React.createRef();

  function handleChangeActivateLicensee(id) {
    axios
      .put(`v1/admin/active-licensee/${id}`)
      .then((response) => {
        getAllLicensee();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function getAllLicensee() {
    showLoading();
    axios.get(linkGetLicense).then((response) => {
      hideLoading();
      setPagination({
        ...pagination,
        totalCount: response.data.licenseeList[0]?.count[0]?.totalCount
          ? response.data.licenseeList[0]?.count[0]?.totalCount
          : null,
      });
      return setListLicensee(response.data.licenseeList[0].licenseeData);
    });
  }

  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header:
            "Are you sure you want to deactivate the licensee from the system ?",
          subtitle:
            "The Licensee will lose access to the app. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate Licensee",
          onActive: () => handleChangeActivateLicensee(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header:
            "Are you sure you want to reactivate the licensee from the system ?",
          subtitle: "The Licensee will again access to the app",
          submitTitle: "Reactivate Licensee",
          onActive: () => handleChangeActivateLicensee(data.id),
        });
      }
    }
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  function resendLoginLicensee(id) {
    axios
      .put(`v1/admin/resend-login/${id}`)
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  return (
    <div className={`t2y-list t2y-licenseelist ${classes.root}`}>
      <Typography variant="h2" component="h2" className={classes.title}>
        Licensee
      </Typography>
      <SearchAndFilter
        optionFilter={radioOptions}
        textAdd="Add Licensee"
        textSearch="Licensees"
        linkAdd="/licensee/signup"
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        pagination={pagination}
        setPagination={setPagination}
      />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        tableRef={tableRef}
        columns={[
          {
            title: <span className="title-table">Licensee</span>,
            field: "licenseeInfo",
            render: (rowData) => {
              return (
                <div className={classes.licenseeInfo}>
                  <ListItem>
                    <ListItemAvatar>
                      {rowData.photo?.data ? (
                        <Avatar alt="Logo" src={rowData.photo.data} />
                      ) : (
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText>
                      <div className="list-contact">
                        <span className="text-16">
                          {rowData.firstname + " " + rowData.lastname}
                        </span>
                        <span className="text-14 text-secondary">
                          {rowData.licensee_data?.licenseeRef}
                        </span>
                      </div>
                    </ListItemText>
                  </ListItem>
                </div>
              );
            },
          },
          {
            title: "Contact Details",
            field: "licenseeContact",
            render: (rowData) => {
              return (
                <div className="list-contact">
                  <span className="text-14">
                    {formatContacts(rowData?.mobileFormat)}
                    {/* {formatContacts(rowData.mobileFormat?.replace("+61", "0"))} */}
                  </span>
                  <span className="text-12">{rowData.email}</span>
                </div>
              );
            },
          },
          {
            title: "Trailers",
            field: "trailers",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnView}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.btnInTable}
                    onClick={() => {
                      history.push({
                        pathname: `/licensee/${rowData.firstname}/${rowData.licenseeId}/trailers`,
                      });
                    }}
                  >
                    View
                  </Button>
                </div>
              );
            },
          },
          {
            title: "Upsell Items",
            field: "upsellItems",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnView}>
                  <ButtonView
                    router={`/licensee/${rowData.firstname}/${rowData.licenseeId}/upsellitems`}
                  />
                </div>
              );
            },
          },
          {
            title: "Employee List",
            field: "employeeList",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnView}>
                  <ButtonView
                    router={`/licensee/${rowData.firstname}/${rowData.licenseeId}/employee/list`}
                  >
                    View
                  </ButtonView>
                </div>
              );
            },
          },
          {
            title: "Uploaded Docs",
            field: "docs",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnView}>
                  <ButtonView router={`/licensee/${rowData.licenseeId}/docs`}>
                    View
                  </ButtonView>
                </div>
              );
            },
          },
          {
            title: "Invite Accepted",
            field: "proofOfIncorporationAccepted",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnInvite}>
                  {rowData.acceptedInvite ? (
                    <span className="text-success">Yes</span>
                  ) : (
                    <span className="text-error">No</span>
                  )}
                </div>
              );
            },
          },
          {
            title: "Rating",
            field: "rating",
            render: (rowData) => {
              return (
                <div className={classes.widthColumnRating}>
                  {rowData.licensee_data?.ratingStar?.ratingStar >= 4 ? (
                    <span className="text-success">
                      {rowData.licensee_data?.ratingStar?.ratingStar}
                    </span>
                  ) : (
                    <span className="text-error">
                      {rowData.licensee_data?.ratingStar?.ratingStar}
                    </span>
                  )}
                </div>
              );
            },
          },
          {
            title: "",
            field: "action",
            render: (rowData) => {
              return (
                <div className="action-table">
                  {!rowData.licensee_data.isActive ? (
                    <ButtonActionListLicensee
                      onClick={() => {
                        toggleDialog(
                          {
                            id: rowData.licenseeId,
                            isActive: rowData.licensee_data.isActive,
                          },
                          "active"
                        );
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Reactivate
                    </ButtonActionListLicensee>
                  ) : (
                    <ButtonActionListLicensee
                      disabled={rowData.acceptedInvite}
                      onClick={() =>
                        resendLoginLicensee(rowData.licensee_data._id)
                      }
                      variant="contained"
                      color="secondary"
                    >
                      Resend logins
                    </ButtonActionListLicensee>
                  )}
                  <div>
                    {rowData.licensee_data.isActive && (
                      <div>
                        <CreateIcon
                          className={classes.iconEdit}
                          onClick={() => {
                            history.push(
                              `/licensee/signup/${rowData.licenseeId}`
                            );
                          }}
                        />
                        <Delete
                          className={classes.iconCreate}
                          onClick={() =>
                            toggleDialog(
                              {
                                id: rowData.licenseeId,
                                isActive: rowData.licensee_data.isActive,
                              },
                              "active"
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            },
          },
        ]}
        data={listLicensee}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  title: {
    marginBottom: "25px",
  },
  iconEdit: {
    color: "#FFFFFF",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  iconCreate: {
    color: "#FF0000",
    fontSize: "19px",
    margin: "auto",
    marginLeft: "18px",
    cursor: "pointer",
  },
  btnInTable: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
  licenseeInfo: {
    minWidth: "250px",
    marginRight: "6px",
  },
  licenseeContact: {
    minWidth: "170px",
    marginRight: "6px",
  },
  widthColumnView: {
    minWidth: "140px",
  },
  widthColumnRating: {
    minWidth: "92px",
  },
  widthColumnInvite: {
    minWidth: "120px",
  },
}));
const radioOptions = [
  {
    name: "Star Rating",
    param: "rating",
    options: [
      {
        text: "4 Stars and above",
        value: "star4",
      },
      {
        text: "Below 4 Stars",
        value: "belowStar4",
      },
    ],
  },

  {
    name: "Account status",
    param: "status",
    options: [
      {
        text: "Active",
        value: "active",
      },
      {
        text: "Deactivated",
        value: "deactivated",
      },
    ],
  },
  {
    name: "Verification",
    param: "verification",
    options: [
      {
        text: "Verified",
        value: "verified",
      },
      {
        text: "Unverified",
        value: "unverified",
      },
    ],
  },
];
