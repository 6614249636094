import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { FieldFormAdd } from "../FieldFormAdd";
import { useEffect, useRef, useState } from "react";
import { Box, Popper, makeStyles } from "@material-ui/core";
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autoCompleteStyle = makeStyles({
  paper: {
    margin: 0,
  },
  listbox: {
    background: `#171717`,
    padding: "8px 0 0 0",
    boxShadow: `0px 0px 5px rgba(83, 95, 120, 0.3)`,
    fontSize: "14px",
    height: "fit-content",
    maxHeight: "50vh",
  },
  option: {
    "&[data-focus='true']": {
      background: "#000000",
    },
    "&:not(:last-child)": {
      boxShadow: `inset 0px -1px 0px #DEE7FA`,
    },
    padding: "16px 12px",
  },
});
export default function SearchPlace({
  title,
  setLocation,
  location,
  errorLocation,
  licensee,
  setLicensee,
  setErrorLocation,
}) {
  const [value, setValue] = React.useState(location.name);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const autocompleteService = React.useRef(null);
  const typingTimeout = React.useRef(null);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBzUIoUo9082hhMQxuCluIn1ybzZE35OeU&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 1000),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    if (options[0]?.place_id) {
      const map = new window.google.maps.Map(
        document.getElementById("google-maps")
      );
      const request = {
        placeId: options[0]?.place_id,
        fields: ["name", "place_id", "geometry", "address_components"],
      };

      const service = new window.google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place &&
          place.geometry &&
          place.geometry.location
        ) {
          let state, city, suburb, postCode;
          state =
            place.address_components.find(
              (item) =>
                JSON.stringify(item.types) ===
                JSON.stringify(["administrative_area_level_1", "political"])
            )?.long_name || "";
          
          suburb = place.address_components.find(
              (item) =>
                JSON.stringify(item.types) ===
                JSON.stringify(["locality", "political"])
            )?.long_name || "";

          city = place.address_components.find(
            (item) =>
              JSON.stringify(item.types) ===
              JSON.stringify(["administrative_area_level_2", "political"])
          )?.short_name || "";

          postCode =
            place.address_components.find(
              (item) =>
                JSON.stringify(item.types) === JSON.stringify(["postal_code"])
            )?.long_name || "";
            
          setLicensee({
            ...licensee,
            city: city,
            suburb: suburb,
            stateWork: state,
            postCode: postCode,
          });
          setLocation({
            geometry: {
              location: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              },
            },
            name: options[0]?.description,
          });
        }
      });
    }
    return () => {};
  }, [value]);
  const container = useRef();
  const [minWidth, setMinWidth] = useState();
  const cssAutoComplete = autoCompleteStyle();
  useEffect(() => {
    if (container.current.id !== null) {
      const containerWidth = document.getElementById("containerSearchField");
      setMinWidth(containerWidth.clientWidth);
    }
    return () => {};
  }, []);
  const PopperMy = function (props) {
    return (
      <Popper
        {...props}
        style={{ width: "100%", maxWidth: `calc(${minWidth}px - 32px)` }}
        placement="bottom"
      />
    );
  };

  return (
    <Box width="100%" id="containerSearchField" ref={container}>
      <Autocomplete
        freeSolo
        classes={cssAutoComplete}
        PopperComponent={PopperMy}
        id="google-place-search"
        disableClearable
        includeInputInList
        filterSelectedOptions
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        value={value}
        onChange={(event, newValue) => {
          setErrorLocation(false);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
          }
          typingTimeout.current = setTimeout(() => {
            setInputValue(newInputValue);
          }, 1000);

          if (!newInputValue) {
            setLocation({});
            setErrorLocation(true);
          }
          setErrorLocation(false);
        }}
        renderInput={(params) => (
          <FieldFormAdd
            {...params}
            id="field3"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            variant="outlined"
            label={title}
            error={errorLocation}
            fullWidth
          />
        )}
        renderOption={(option) => {
          let matches =
            option.structured_formatting?.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting?.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );
          return (
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 600 : 600,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </Box>
  );
}
