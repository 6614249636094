import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
export const OverLayContext = React.createContext({});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function OverLayProvider(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    return (
        <OverLayContext.Provider value={{
            setLoading
        }}>
            {loading && (
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="secondary" />
                </Backdrop>
            )}
            {props.children}
        </OverLayContext.Provider>
    )
}

export default OverLayProvider;