import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { Box, Button, makeStyles } from "@material-ui/core";
import { ReactComponent as Edit } from "../../img/edit.svg";
import ColumnInTable from "../../components/ColumnInTable";
import { useLoading } from "../../hooks/useLoading";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import moment from "moment";
import { titleCase } from "../../helpers/format";
import { toast } from "react-toastify";
// ENUM STATUS
const DEACTIVATE = "Deactivate";
const REACTIVATE = "Reactivate";
const TrailerListInLicensee = () => {
  const [listTrailerOfLicensee, setListTrailerOfLicensee] = useState([]);
  const { licenseeId, firstname } = useParams();
  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const classes = useStyles();

  useEffect(() => {
    getAll();
  }, []);
  // DATA TABLE
  function handleChangeActivate(id) {
    showLoading();
    axios
      .put(`v1/admin/trailer-licensee-active/${licenseeId}/${id}`)
      .then((response) => {
        hideLoading();
        getAll();
        setOpen(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function getAll() {
    showLoading();
    axios
      .get("/v1/admin/licensee/trailers-Upsells/" + licenseeId)
      .then((response) => {
        hideLoading();
        return setListTrailerOfLicensee(response.data.licenseeTrailersObj);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  const mockData = [
    {
      title: <span className="title-table">Trailer type</span>,
      field: "trailerType",
      render: (rowData) => {
        return (
          <div style={{ minWidth: "250px", marginRight: "25px" }}>
            {rowData.trailerId.name}
          </div>
        );
      },
    },
    {
      title: "Size",
      field: "size",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">{rowData.trailerId.size}</ColumnInTable>
        );
      },
    },
    {
      title: "Axle",
      field: "axle",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {titleCase(rowData.trailerId.axel)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Tare",
      field: "tare",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {rowData.trailerId.tare}kg
          </ColumnInTable>
        );
      },
    },
    {
      title: "Max Load",
      field: "maxLoad",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {rowData.trailerId.maxLoad}kg
          </ColumnInTable>
        );
      },
    },
    {
      title: "GVM",
      field: "GVM",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {rowData.trailerId.gvm}kg
          </ColumnInTable>
        );
      },
    },
    {
      title: "Hire Cost",
      field: "hireCost",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="125">
            ${Math.ceil(rowData.hirecost)} per day
          </ColumnInTable>
        );
      },
    },
    {
      title: "Service Checklist",
      field: "serviceChecklist",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="140">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.root}
              onClick={() => history.push(`/licensee/${licenseeId}/trailers-service/${rowData._id}`)}
            >
              View
            </Button>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Service Status",
      field: "serviceStatus",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="115">
            <div
              className={
                renderStatusService(rowData.checklist)
                  ? "text-success"
                  : "text-error"
              }
            >
              {renderStatusService(rowData.checklist) ? "Complete" : "Pending"}
            </div>
          </ColumnInTable>
        );
      },
    },
    {
      title: "Registration",
      field: "registration",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="113">
            {!rowData.registration ? "---" : rowData.registration}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Registration Status",
      field: "registrationStatus",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="115">
            {renderStatusRegistration(rowData.expiryRegistration)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <div
              style={{
                marginRight: "15px",
                padding: "9.5px",
                width: "fit-content",
              }}
              onClick={() => {
                history.push(`/licensee/${licenseeId}/trailer/edit/${rowData._id}`)
              }
              }
            >
              <Edit />
            </div>
            <ButtonActionSubPage
              colorError={rowData.isActive ? true : false}
              onClick={() => {
                toggleDialog(
                  {
                    id: rowData._id,
                    isActive: rowData.isActive,
                  },
                  "active"
                );
              }}
            >
              {rowData.isActive ? DEACTIVATE : REACTIVATE}
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  const renderStatusService = (serviceList) => {
    if (!serviceList || serviceList.length === 0) {
      return false;
    } else {
      if (
        moment().diff(moment(serviceList[serviceList.length - 1]), "days") < 30
      ) {
        return true;
      }
      return false;
    }
  };
  const renderStatusRegistration = (expiry) => {
    if (!expiry) {
      return <span>---</span>;
    } else {
      if (moment().diff(moment(expiry), "days") < 0) {
        return <span className="text-success">Valid</span>;
      }
      return <span className="text-error">Expired</span>;
    }
  };

  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header:
            "Are you sure you want to deactivate this trailer from the licensee’s app ?",
          subtitle:
            "The Licensee will lose access to this trailer. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate Trailer",
          onActive: () => handleChangeActivate(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header:
            "Are you sure you want to reactivate this trailer to the licensee’s app ?",
          subtitle: "The Licensee will gain access to this trailer again",
          submitTitle: "Reactivate Trailer",
          onActive: () => handleChangeActivate(data.id),
        });
      }
    }
    setOpen(true);
  }

  function closeDialog() {
    setOpen(false);
  }

  return (
    <Box height="100%">
      <WrapHeaderSubPage nameHeader={`${firstname}'s Trailers`}>
        <ButtonActionHeader
          onClick={() =>
            history.push(`/licensee/${firstname}/${licenseeId}/trailer/add`)
          }
        >
          Add Trailer
        </ButtonActionHeader>
      </WrapHeaderSubPage>
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={listTrailerOfLicensee}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
    </Box>
  );
};

export default TrailerListInLicensee;
const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
}));
