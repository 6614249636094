import React from "react"
import { withStyles } from "@material-ui/styles"
import { Button } from "@material-ui/core"
const ButtonActionSubPage = withStyles(theme => ({
  root: {
    width: props => props.minWidth ? `${props.minWidth}` : "114px",
    height: props => props.height? `${props.height}`: "33px",
    padding: props => props.padding ? `${props.padding}` : "8px 9px",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: props => props.fontWeight ? `${props.fontWeight}` : 500,
    backgroundColor: props =>  props.colorError ? `#FF0000` : `#44D62C`,
    "&:hover": {
      backgroundColor: props => props.colorError ? `rgb(195,0,0)` : `#2f981e`
    }
  },
  label: {
    lineHeight: props => props.height? `${props.height}`: "33px",
  }
}))((props) => {
  const { colorError, minWidth, ...other } = props
  return ( 
    <Button {...other}>
      {props.children}
    </Button>
  )
})
export default ButtonActionSubPage