import React, { useState, useEffect } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { useHistory, useParams } from "react-router-dom";
import { ButtonCircle } from "../../components/ButtomCircle";
import { Typography, Button } from "@material-ui/core";
import ItemAddTrailer from "../../components/ItemAddTrailer";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
const AddUpsellLicensee = () => {
  let history = useHistory();
  const [upsellList, setUpsellList] = useState([]);
  const [upsellAddList, setUpsellAddList] = useState([]);
  const { firstname, licenseeId } = useParams();
  const [showLoading, hideLoading] = useLoading();
  useEffect(() => {
    axios.get("/v1/admin/get-all-upsellitems").then((response) => {
      return setUpsellList(
        response.data.dataObj.map((item) => {
          return { quantity: 0, upsell: item };
        })
      );
    });
  }, []);
  const handleChangeQuantity = (id, method, type) => {
    if (type === "upsell") {
      const temp = [...upsellList];
      for (let item of temp) {
        if (item.upsell._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }
      let temp2 = [];
      for (let item of temp.filter((trailer) => trailer.quantity > 0)) {
        for (let i = 0; i < item.quantity; i++) {
          temp2.push({
            upsellItemId: item.upsell._id,
          });
        }
      }
      setUpsellAddList(temp2);
      setUpsellList(temp);
    }
  };

  const handleAddUpsell = async () => {
    if (upsellAddList.length < 1) {
      history.goBack();
      return;
    }
    showLoading();
    try {
      const formData = new FormData();
      for (let item of upsellAddList) {
        formData.append("upsellItems[]", JSON.stringify(item));
      }
      const res = await axios.put(
        "v1/admin/licensee/addupsellitem/" + licenseeId,
        formData
      );
      toast.success(res?.data?.message);
      hideLoading();
      history.goBack();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <LayoutAddOrEdit
      titleDetail={`Add new upsell item to ${firstname}'s' list`}
    >
      <div className="btn-exit">
        <ButtonCircle variant="contained" onClick={() => history.goBack()}>
          Exit
        </ButtonCircle>
      </div>
      <div className="form-main">
        <Typography variant="h3">Select upsell items</Typography>

        <div className="list-add-trailer">
          {upsellList.map((item, index) => (
            <ItemAddTrailer
              key={index}
              name={item.upsell.name}
              urlImage={item.upsell.photos[0]?.url}
              quantity={item.quantity}
              handleSubQuantity={() =>
                handleChangeQuantity(item.upsell._id, "subtract", "upsell")
              }
              handleAddQuantity={() =>
                handleChangeQuantity(item.upsell._id, "add", "upsell")
              }
            />
          ))}
        </div>
        <div className="footter-process">
          <div className="btn-add-trailer">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUpsell}
            >
              Add Upsell Item
            </Button>
          </div>
        </div>
      </div>
    </LayoutAddOrEdit>
  );
};

export default AddUpsellLicensee;
