import React from "react"
import { withStyles } from "@material-ui/styles"
import { Button } from "@material-ui/core"
const ButtonActionHeader = withStyles(theme => ({
  root: {
    width: props => props.width ? props.width : "173px",
    height: "41px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
}))((props) => 
  <Button 
    variant="contained"
    color="primary"
    {...props} 
  >
    {props.children}
  </Button>
)
export default ButtonActionHeader