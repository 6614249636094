export const common = {
  country: "Australia",
  states: [
    "New South Wales",
    "Victoria",
    "Tasmania",
    "Queensland",
    "South Australia",
    "Western Australia",
  ],
  businessType: ["Individual", "Company"],
  itemType: ["Upsell Item", "Consumable Item"],
  days: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  // daysShort: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
  daysShort: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  hours: "1000-1900",
  hoursStart: "10:00",
  hoursEnd: "19:00",

  mcc: "7513",
  productDescription: "Trailer Rental",
  licenseeEmployeeType: [
    "employee",
    "owner",
    "representative",
    "director",
    "executive",
  ],
  formatTime: {
    1: "[0-2]",
    2: "[0-9]",
    3: "[0-5]",
    4: "[0-9]",
  },
  formatDate: {
    1: "[0-1]",
    2: "[0-9]",
    3: "[0-3]",
  },
  formatNumber: {
    9: "[0-9]",
  },
  // apiUrl: "http://192.168.31.233:5000",
  // apiUrl: "https://t2ytest-private.herokuapp.com",
  apiUrl: "https://trailer2you-be.herokuapp.com",
  // apiUrl: "https://t2ybeta.herokuapp.com",
};
