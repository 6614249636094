import React, { useState, useRef, useEffect } from "react";
import TemplateImageItemLicensee from "../../components/TemplateImageItemLicensee";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DynamicTextField from "../../components/DynamicTextField";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { Box, makeStyles } from "@material-ui/core";
import TextFieldMask from "../../components/TextFieldMask";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  generatePropTextField,
} from "../../helpers/formik-help";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import { calculateHireCostUpsell } from "../../helpers/api";
import { CustomTextField } from "../../components/SelectField";
import { useLoading } from "../../hooks/useLoading";
import { toast } from "react-toastify";
import axios from "../../helpers/axios";
import { useHistory } from "react-router-dom";
import { common } from "../../constants/common";
import ButtonActionHeader from "../../components/ButtonActionHeader";

const defaultDataRef = {
  name: "",
  price: "",
  type: "",
  description: "",
};
const UpsellAdd = () => {
  const [upsellData, setUpsellData] = useState(defaultDataRef);
  const [pricePerDay, setPricePerDay] = useState();
  let history = useHistory();
  const dataImageRef = useRef(null);
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  const typingTimeout = React.useRef(null);

  const formik = useFormik({
    initialValues: upsellData,
    validationSchema: yup.object({
      name: yup.string().required(" "),
      price: yup.string().required(" "),
      type: yup.string().required(" "),
      description: yup.string().required(" "),
    }),
    onSubmit: (value) => {
      handleAddOrUpdateTrailer(value);
    },
  });

  const handleAddOrUpdateTrailer = async (upsellData) => {
    showLoading();
    try {
      const formData = new FormData();
      formData.append("name", upsellData.name);
      formData.append("price", upsellData.price.slice(1));
      formData.append(
        "consumeable",
        upsellData.type !== "consumable item" ? false : true
      );
      formData.append("description", upsellData.description);
      if (dataImageRef.current) {
        for (let item of dataImageRef.current) {
          formData.append("photos", item);
        }
      }
      const res = await axios.post("v1/admin/upsellitem", formData);
      toast.success(res?.data?.message);
      hideLoading();
      history.push("/rental/upsell");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    typingTimeout.current = setTimeout(() => {
      calculateHireCostUpsell(
        formik.values.price.toString().replace("$", ""),
        setPricePerDay
      );
    }, 1000);
  }, [formik.values.price]);
  return (
    <Box height="100%" paddingBottom="85px">
      <WrapHeaderSubPage nameHeader="Add item">
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Box display="flex">
        {/* UPLOAD IMAGE TRAILER  */}
        <TemplateImageItemLicensee dataImageRef={dataImageRef} />
        <WrapFormItemLicensee formik={formik}>
          <DynamicTextField
            {...generatePropTextField("name", formik)}
            label="Add Item Name"
          />
          <CustomTextField
            name="type"
            id="type"
            label="Item Type"
            {...generatePropTextField("type", formik)}
            options={common.itemType}
            classMenuItem={classes.menuItem}
          />
          <div className="price">
            <TextFieldMask
              {...generatePropTextField("price", formik)}
              label="Item Value"
              mask="$99999999999"
              autoComplete="off"
              formatChars={{ 9: "[0-9]" }}
            >
              {(inputProps) => (
                <DynamicTextField
                  fullWidth
                  variant="outlined"
                  {...inputProps}
                />
              )}
            </TextFieldMask>
            <div className="price-perDay">
              {!!pricePerDay && `$${pricePerDay} per day`}
            </div>
          </div>

          <DynamicTextField
            {...generatePropTextField("description", formik)}
            label="Description"
            multiline
            rows={9}
          />
          <WrapButtonSubmitTrailer>
            <ButtonActionHeader 
              width="233px" onClick={() => formik.handleSubmit()}
            >
              Upload new item
            </ButtonActionHeader>
          </WrapButtonSubmitTrailer>
        </WrapFormItemLicensee>
      </Box>
    </Box>
  );
};
export default UpsellAdd;
const useStyles = makeStyles(() => ({
  input50: {
    width: "calc( (50vw - 200px ) / 2 - 9px)",
  },
  menuItem: {
    height: "40px",
    padding: "12px 16px",
    fontSize: "14px",
    boxShadow: "inset 0px -1px 0px #ECF1FC",
    "&:last-child": {
      boxShadow: "none",
    },
  },
}));
