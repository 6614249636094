import React from "react"
import { Alert } from "@material-ui/lab"
import { IconButton, Collapse, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
const useStyle = makeStyles({
  root: {
    background: "#FFFFFF",
    border: "2px solid #FF0000",
    padding: "24px 22px",
    borderRadius: "8px",
    alignItems: "center",
    color: "rgba(0,0,0,1)",
    boxSizing: "content-box"
  },
  message: {
    padding: 0
  },
  icon: {
    padding: 0
  }
})
const AlertPopup = (props) => {

  const classes = useStyle()
  return (
    <Collapse className="" in={props.openAlert}>
      <Alert
        icon={false}
        classes={{
          root: classes.root,
          message: classes.message,
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            classes={{
              root: classes.icon
            }}
            onClick={() => {
              props.setOpenAlert(false)
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {props.title}
      </Alert>
    </Collapse>
  )
}
export default AlertPopup