import React from "react"
import { Box, Typography } from "@material-ui/core"
const WrapHeaderSubPage = (props) => {
  return (
    <Box 
      display="flex" justifyContent="space-between" marginBottom="35px"
      flexDirection= { props.flexDirection ? "column" : "row" }
    >
      <Typography variant="h2" component="h2" style={{ textTransform: "capitalize" }}>
        {props.nameHeader}
      </Typography>
      {props.children}
    </Box>
  )
}
export default WrapHeaderSubPage