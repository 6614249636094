import moment from "moment";
import { toast } from "react-toastify";
export function isEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return true;
  }
  return false;
}
export function isNumber(val) {
  let regNum = /^[0-9]*$/;
  if (!val) {
    return true;
  }
  if (regNum.test(val)) {
    return true;
  }
  return false;
}

export function isMobilePhone(val) {
  return (
    !val ||
    (val.charAt(0) === "4" && val.length === 9 && isNumber(val)) ||
    (val.charAt(0) === "0" &&
      val.charAt(1) === "4" &&
      val.length === 10 &&
      isNumber(val))
  );
}

export function isTime(val) {
  const validTime = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  if (validTime.test(val) && val.length > 4) {
    return true;
  }
  return false;
}
export function isDate(val) {
  const validDate =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(\d{2})$/;
  if (!val) {
    return true;
  }
  if (validDate.test(val)) {
    return true;
  }
  return false;
}
export function isFullDate(val) {
  const validDate =
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4\d{4}$/;

  if (!val) {
    return true;
  } else if (val.length < 10) {
    return false;
  } else if (validDate.test(val)) {
    return true;
  }
  return false;
}

export const isImgFile = (file) => {
  if (!file) {
    return false;
  } else if (
    file.type === "image/jpg" ||
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "application/pdf" ||
    file.name.slice(-4) === "heic" ||
    file.name.slice(-3) === "bmb" ||
    file.name.slice(-3) === "gif" ||
    file.name.slice(-3) === "jpg"
  ) {
    if (file.size > 26214400) {
      toast.error("File too Big, please select a file less than 25mb!");
      return false;
    } else {
      return true;
    }
  } else {
    toast.error("File type not supported, please try again!");
    return false;
  }
};

export const checkTimeStartEnd = (start, end) => {
  return moment(start, "HH:mm").diff(moment(end, "HH:mm")) < 0;
};
