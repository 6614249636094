import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import ColumnInTable from "../../components/ColumnInTable";
import { useLoading } from "../../hooks/useLoading";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import CustomPagination from "../../components/Pagination";
import moment from "moment";

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });
  const [linkGetTransaction, setLinkGetTransaction] = useState(
    `/v1/admin/transaction?count=${pagination.count}&&skip=${pagination.skip}`
  );

  useEffect(() => {
    setLinkGetTransaction(
      `/v1/admin/transaction?count=${pagination.count}&&skip=${pagination.skip}`
    );
  }, [pagination]);

  useEffect(() => {
    getAll();
  }, [linkGetTransaction]);
  function getAll() {
    showLoading();
    axiosInstance.get(linkGetTransaction).then((response) => {
      hideLoading();
      setPagination({
        ...pagination,
        totalCount: response.data.totalCount ? response.data.totalCount : null,
      });
      return setTransactions(response.data.transactionsObj);
    });
  }
  const mockData = [
    {
      title: <span className="title-table">Amount</span>,
      field: "amount",
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: "200px",
              whiteSpace: "nowrap",
              lineHeight: "58px",
              paddingRight: "18px",
              fontWeight: "bold",
            }}
          >
            ${rowData.charges.total}
          </div>
        );
      },
    },
    {
      title: "Licensee's received amount",
      field: "amount",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="200">
            ${Number(
              (rowData.charges?.total - rowData.charges?.transitionFee) * 0.85
            ).toFixed(2)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "T2Y's received amount",
      field: "amount",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="200">
            ${(
              rowData.charges?.total -
              Number(
                (rowData.charges?.total - rowData.charges?.transitionFee) * 0.85
              )
            ).toFixed(2)}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return <ColumnInTable minWidth="200">{rowData.status}</ColumnInTable>;
      },
    },
    {
      title: "Description",
      field: "description",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="450">
            Payment for invoice(s) {rowData.stripePaymentIntentId}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Customer",
      field: "customer",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="245">
            {rowData.customerInfor.email}
          </ColumnInTable>
        );
      },
    },
    {
      title: "Date",
      field: "date",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="100">
            {moment(rowData.createdAt).format("DD MMM, HH:MM")}
          </ColumnInTable>
        );
      },
    },
  ];

  return (
    <>
      <WrapHeaderSubPage
        nameHeader="Transaction History"
        style={{ marginBottom: "50px" }}
      ></WrapHeaderSubPage>
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={transactions}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </>
  );
};
export default TransactionHistory;
