import React, { useState, useRef, useEffect } from "react";
import TemplateImageItemLicensee from "../../components/TemplateImageItemLicensee";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage";
import DynamicTextField from "../../components/DynamicTextField";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import ButtonActionHeader from "../../components/ButtonActionHeader"
import { Box } from "@material-ui/core";
import TextFieldMask from "../../components/TextFieldMask";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  generatePropTextField,
} from "../../helpers/formik-help";
import { toast } from "react-toastify";

import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "../../helpers/axios";
import { useLoading } from "../../hooks/useLoading";
import moment from "moment";
import { common } from "../../constants/common";
import { isFullDate } from "../../helpers/verify";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import AlertMissingDocument from "../../components/AlertMissingDocument";
import { checkIsDocumentExpiry } from "../../helpers/checkIsDocumentExpiry";

const CONTENT_ALERT = {
  true: {
    header: "Trailer Missing Expiry Registration",
  },
  7: {
    header: "Trailer will be Expiry Registration in 7 days"
  }, 
  30: {
    header: "Trailer will be Expiry Registration in 30 days"
  },
  title: "Contact the Licensee to discuss this issue"
}
const EditTrailerItemInLicensee = () => {
  const [trailerData, setTrailerData] = useState({
    name: "",
    registration: "",
    description: "",
    expiryRegistration: "",
    photos: []
  });
  const [ isExpiry, setExpiry ] = useState(false)
  const dataImageRef = useRef(null);
  const history = useHistory();
  const { licenseeId, trailerId } = useParams();
  const [showLoading, hideLoading] = useLoading();

  const handleAddRegistrationTrailer = async (
    registration,
    expiryRegistration
  ) => {
    showLoading();
    try {
      const res = await axios.put(
        "v1/admin/trailer-licensee-registration/" +
          licenseeId +
          "/" +
          trailerId,
        { registration, expiryRegistration }
      );
      toast.success(res?.data?.message);
      hideLoading();
      history.goBack();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: trailerData,
    validationSchema: yup.object({
      registration: yup.string().required(" "),
      expiryRegistration: yup.string().min(10, " ").required(" "),
    }),
    onSubmit: (value) => {
      handleAddRegistrationTrailer(
        value.registration,
        value.expiryRegistration
      );
    },
  });

  useEffect(() => {
    showLoading();
    axios.get("/v1/admin/licensee/trailers-Upsells/" + licenseeId)
      .then((response) => {
        const trailerOflicenseeList=  response.data.licenseeTrailersObj
        let item = trailerOflicenseeList.find(item => item._id === trailerId )
        setTrailerData({
          name: item.trailerId.name,
          registration: item.registration || "",
          description: item.trailerId.description,
          expiryRegistration: 
            item.expiryRegistration
              ? moment(item.expiryRegistration).format("DD/MM/YYYY")
              : "",
          photos: item.trailerId.photos?.map((item) => item.url)  
        });
        if ( item.expiryRegistration === null ) {
          setExpiry(true)
          return hideLoading();
        }
        const isRegistraionExpiry = checkIsDocumentExpiry(item.expiryRegistration)
        setExpiry(isRegistraionExpiry)
        return hideLoading();
      }).catch((err) => {
        hideLoading();
        toast.error(err?.response?.data?.message);
      } )
  },[])

  return (
    <Box height="100%">
      <WrapHeaderSubPage nameHeader={trailerData.name}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <Box display="flex">
        <TemplateImageItemLicensee
          dataImageRef={dataImageRef}
          photos={trailerData.photos}
          upload={false}
        />
        <WrapFormItemLicensee formik={formik}>
          <DynamicTextField
            {...generatePropTextField("registration", formik)}
            label="Trailer Registration"
          />
          <TextFieldMask
            formatChars={common.formatDate}
            mask="32/12/2222"
            value={formik.values.expiryRegistration}
            onChange={formik.handleChange}
            error={
              (formik.touched.expiryRegistration &&
                Boolean(formik.errors.expiryRegistration)) ||
              !isFullDate(formik.values.expiryRegistration)
            }
            inputProps={{
              maxLength: 250,
            }}
            id="expiryRegistration"
            name="expiryRegistration"
            label="Expiry Registration"
          >
            {(inputProps) => (
              <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
            )}
          </TextFieldMask>
          <span className="text-16">{trailerData.description}</span>
          <WrapButtonSubmitTrailer>
            <ButtonActionHeader 
              style={{ marginTop: "32px"}}
              width="233px" onClick={() => formik.handleSubmit()}
            >
              Save Update
            </ButtonActionHeader>
          </WrapButtonSubmitTrailer>
        </WrapFormItemLicensee>
      </Box>
      {
        isExpiry !== false && 
          <AlertMissingDocument 
            header={CONTENT_ALERT[isExpiry].header}
            title={CONTENT_ALERT.title}
            contact="04 26 854 685"
          />
      }
    </Box>
  );
};
export default EditTrailerItemInLicensee;
