import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { ReactComponent as Folder } from "../../img/folder.svg";
import { ReactComponent as InfoDocument } from "../../img/infor.svg";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../helpers/axios";
import moment from "moment";
const useStyles = makeStyles({
  root: {
    display: "flex",
    paddingBottom: "15px",
    margin: "0 -10.5px",
    flexWrap: "wrap",
  },
  containerItem: {
    display: "flex",
    flexDirection: "column",
    minWidth: "314px",
    height: "284px",
    alignItems: "center",
    padding: "25px",
    borderRadius: "15px",
    backgroundColor: "#1C1C1C",
    justifyContent: "center",
    boxSizing: "border-box",
    position: "relative",
    margin: "0 10.5px 21px 10.5px",
  },
  containerIconInfoDocument: {
    position: "absolute",
    width: "fit-content",
    height: "fit-content",
    top: 12,
    right: 12,
  },
  titleDocument: {
    fontSize: 18,
    marginTop: "38px",
    width: "154px",
    textAlign: "center",
    fontWeight: "600",
    lineHeight: "21.5px",
  },
});

const checkIsItemDocumentExpiry = (dayDocumentExpiry) => {
  const timeLeftToExpiryDay = moment(dayDocumentExpiry).diff(moment(), "days");
  if (timeLeftToExpiryDay > 30) {
    return false;
  }
  if (timeLeftToExpiryDay <= 30) { 
    return true
  }
};

const DOCUMENT_LIST = [
  {
    name: "Business registration",
    location: `/licensee/:replaceId/insurance/business-registration`,
    keyData: "businessRegistration",
    keyExpiry: "businessRegistrationExpiry",
  },
  {
    name: "Business insurance",
    location: `/licensee/:replaceId/insurance/business-insurance`,
    keyData: "businessInsurance",
    keyExpiry: "businessInsuranceExpiry",
  },
  {
    name: "Proof of incorporation",
    location: `/licensee/:replaceId/insurance/proof-of-incorporation`,
    keyData: "proofOfIncorporation",
  },
  {
    name: "Identification",
    location: `/licensee/:replaceId/insurance/identification`,
  },
];
const addParamId = (list, id) => {
  return list.map((element) => ({
    ...element,
    location: element.location.replace(":replaceId", `${id.licenseeId}`),
  }));
};
const NewDocumentListInLicensee = () => {
  const [licensee, setLicensee] = useState([]);
  const [isItemDocumentExpiry, setItemDocumentExpiry] = useState({
    businessRegistration: false,
    businessInsurance: false,
  });
  const classes = useStyles();
  const history = useHistory();
  const paramId = useParams();

  useEffect(() => {
    axios
      .get(`v1/admin/detaillicensee/${paramId.licenseeId}`)
      .then((response) => {
        setLicensee(response.data.licenseeUserObj)
        DOCUMENT_LIST.slice(0, 2).map((item) =>
          setItemDocumentExpiry((prev) => ({
            ...prev,
            [item.keyData]: checkIsItemDocumentExpiry(
              response.data.licenseeUserObj.licenseeId[item.keyExpiry]
            ),
          }))
        );
      });
  }, []);
  return (
    <div>
      <WrapHeaderSubPage
        nameHeader={`${licensee?.fullname}’s Documents`}
      />
      <Box className={classes.root}>
        {addParamId(DOCUMENT_LIST, paramId).map((item, index) => {
          return (
            <Box
              key={index}
              className={classes.containerItem}
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: item.location,
                  state: {
                    keyExpiry: item.keyExpiry,
                    keyData: item.keyData,
                    name: item.name,
                  },
                })
              }
            >
              <Folder />
              {isItemDocumentExpiry[item.keyData] && (
                <InfoDocument className={classes.containerIconInfoDocument} />
              )}
              <Box className={classes.titleDocument}>
                {item.name}
              </Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};
export default NewDocumentListInLicensee;
