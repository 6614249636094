import React from "react"
import { Box, makeStyles } from "@material-ui/core";

const wrapViewImageStyles = makeStyles(() => ({
  root: {
    backgroundImage: props => `url(${props.url})`,
    width: "152px",
    height: "152px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "76px",
    boxSizing: "border-box",
    padding: "5px",
    border: "3px solid rgba(68, 214, 44, 1)",
  },
}));

const AvatarAdminUser = (props) => {
  const item = {
    url: props.url
  } 
  const cssWrapViewImageItem = wrapViewImageStyles(item);
  return (
    <Box classes={cssWrapViewImageItem} />
  )
}
export default AvatarAdminUser