import React, { Fragment } from "react";
import { ReactComponent as Logo } from "../../img/logo.svg";
import {
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import "../../App.scss";
import { Box } from "@material-ui/core";
import { useStyles } from "./style";
import ButtonNotifications from "../ButtonNotifications";
import ButtonNavLink from "../ButtonNavlink"
import ButtonMenuNavlink from "../ButtonMenuNavlink"
import NAVLINK_LIST from "./constant";

function AppHeader({ showMenu }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.colorPrimary}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" alignItems="center" width="100%">
              <Logo />
              {showMenu && (
                <Box 
                  width="100%"
                  display="flex" 
                  alignItems="center" 
                  justifyContent="space-between" 
                  marginLeft="10px"
                >
                  <Box display="flex" alignItems="center">
                    {NAVLINK_LIST.map((item, i) => 
                      <Fragment key={i}>
                        {
                          !item.options ? 
                            <ButtonNavLink
                              numberKey={i}
                              link={item.link}
                              name={item.name}
                            /> 
                          :
                            <ButtonMenuNavlink
                              name={item.name}
                              options={item.options}
                            />
                        }
                      </Fragment>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ButtonNotifications />
                    <Box
                      borderLeft="1px solid #FFFFFF"
                      height="36px"
                      marginRight="16px"
                    />
                    <Button
                      className={classes.navLogoutButton}
                      onClick={() => {
                        localStorage.removeItem("expiry");
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                        localStorage.removeItem("aclSettingsAdmin");
                        history.push("/signin");
                      }}
                    >
                      Log out
                    </Button>
                  </Box>  
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppHeader;
