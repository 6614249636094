import React from "react";
import { Button, withStyles } from "@material-ui/core";

const ButtonActionListLicensee = withStyles({
  root: {
    whiteSpace: "nowrap",
    padding: "8px 9px",
    height: "33px",
    color: "#FFFFFF !important",
    minWidth: 107,
  },
  label: {
    lineHeight: "15px",
  },
  disabled: {
    backgroundColor: "rgba(100, 101, 101, 1)!important",
  },
})((props) => {
  return <Button {...props}>{props.children}</Button>;
});
export default ButtonActionListLicensee;
