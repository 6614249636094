import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import "./style.scss";
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 25,
    padding: 0,
    marginLeft: 2,
    display: "flex",
    borderRadius: "53.125px",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    marginLeft: 0,
    "&$checked": {
      transform: "translateX(23px)",
      color: theme.palette.common.white,

      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: "none",
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(68,214,44,0.2)",
  },
  checked: {},
}))(Switch);

export default function SwitchCus(props) {
  return (
    <div className="switch-cus">
      <AntSwitch
        checked={props.checked}
        onChange={props.onChange}
        name="checkedC"
      />
      <span
        className={`switch-cus-text ${
          props.checked ? "text-open" : "text-close"
        }`}
      >
        {props.checked ? "Open" : "Close"}
      </span>
    </div>
  );
}
