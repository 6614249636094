import { Link as RouterLink } from "react-router-dom";
import { PageNotFoundIllustration } from "../assets";
import { Box, Typography, Container, Button } from "@material-ui/core";
import React from "react";
export default function Page404() {
  return (
    <Container>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </Typography>
        <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}
