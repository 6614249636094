import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import SearchCustomer from "./SearchCustomer";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import ColumnInTable from "../../components/ColumnInTable";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import CustomPagination from "../../components/Pagination";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import DialogChangeStatus from "../../components/DialogChangeStatus";
import DialogIdentifycation from "../../components/DialogIdentification";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
}));
const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [showLoading, hideLoading] = useLoading();
  const [pagination, setPagination] = useState({
    count: 20,
    skip: 0,
    totalCount: 0,
  });

  const [isOpenDialogDriverLicense, setOpenDialogDriverLicense] =
    useState(false);
  const [isOpenDialogStatusCustomer, setOpenDialogStatusCustomer] =
    useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });
  const [listDriverLicense, setListDriverLicense] = useState([]);
  const queryAPI = useRef("");
  const classes = useStyles();
  const mockData = [
    {
      title: <span className="title-table">Customer</span>,
      field: "trailerType",
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: "301px",
              whiteSpace: "nowrap",
              lineHeight: "58px",
              paddingRight: "18px",
            }}
          >
            {rowData.name}
          </div>
        );
      },
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
      render: (rowData) => {
        return <ColumnInTable minWidth="289">{rowData.displayMobile}</ColumnInTable>;
      },
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => {
        return <ColumnInTable minWidth="300">{rowData.email}</ColumnInTable>;
      },
    },
    {
      title: "Drivers license",
      field: "driversLicense",
      render: (rowData) => {
        return (
          <ColumnInTable minWidth="245">
            <Button
              variant="outlined"
              color="secondary"
              className={classes.root}
              onClick={() => handleStateDriverLicense(rowData)}
            >
              View
            </Button>
          </ColumnInTable>
        );
      },
    },
    {
      title: "",
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage
              colorError={rowData.isActive ? true : false}
              minWidth="144px"
              onClick={() => {
                toggleDialog(
                  {
                    id: rowData._id,
                    isActive: rowData.isActive,
                  },
                  "active"
                );
              }}
            >
              {rowData.isActive ? "Deactivate Customer" : "Reactivate Customer"}
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];
  function toggleDialog(data, method) {
    if (method === "active") {
      if (data.isActive) {
        setDataDialog({
          isActive: false,
          header:
            "Are you sure you want to deactivate the customer from the system ?",
          subtitle:
            "The customer will lose access to the app. You can grant them access once any issues are resolved",
          submitTitle: "Deactivate Customer",
          onActive: () => handleChangeActivate(data.id),
        });
      } else {
        setDataDialog({
          isActive: true,
          header: "Are you sure you want to reactivate the  customer to the system ?",
          subtitle: "The customer gain access to the app again",
          submitTitle: "Reactivate Customer",
          onActive: () => handleChangeActivate(data.id),
        });
      }
    }
    setOpenDialogStatusCustomer(true);
  }

  async function handleChangeActivate(id) {
    showLoading();
    await axios
      .put(`v1/admin/active-customer/${id}`)
      .then((response) => {
        hideLoading();
        closeDialogStatusCustomer();
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
    await getCustomerByPagination();
  }

  function handleStateDriverLicense(data) {
    const result = data.driverLicense.scans;
    setListDriverLicense(result);
    setOpenDialogDriverLicense(true);
  }

  function closeDialogDriverLicense() {
    setOpenDialogDriverLicense(false);
  }

  function closeDialogStatusCustomer() {
    setOpenDialogStatusCustomer(false);
  }

  function handleSubmitSearch(e, value) {
    e.preventDefault();
    queryAPI.current = value;
    let query = `/v1/admin/customer?count=${pagination.count}&&skip=0`;
    getCustomerByPagination(query, 0);
  }

  function getCustomerByPagination(
    query = `/v1/admin/customer?count=${pagination.count}&&skip=${pagination.skip}`,
    skip = pagination.skip
  ) {
    if (queryAPI.current !== "") {
      query = query + `?&&searchs=${queryAPI.current}`;
    }
    showLoading();
    axios
      .get(query)
      .then((response) => {
        hideLoading();
        setCustomers(response.data.customersObj);
        setPagination({
          count: pagination.count,
          skip: skip,
          totalCount: response.data.totalCount,
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  useEffect(() => {
    getCustomerByPagination();
  }, [pagination.skip, pagination.count]);
  return (
    <>
      <WrapHeaderSubPage
        nameHeader="Customers"
        style={{ marginBottom: "50px" }}
        flexDirection="column"
      >
        <SearchCustomer textSearch="Customer" onSubmit={handleSubmitSearch} />
      </WrapHeaderSubPage>
      <DialogIdentifycation
        open={isOpenDialogDriverLicense}
        onClose={closeDialogDriverLicense}
        data={{
          header: "Drivers License",
          list: listDriverLicense,
        }}
      />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpenDialogStatusCustomer}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialogStatusCustomer}
      />
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={customers}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
      <CustomPagination pagination={pagination} setPagination={setPagination} />
    </>
  );
};
export default CustomerList;
