import { makeStyles } from "@material-ui/core";
export const imageItemStyles = makeStyles({
  root: {
    width: "calc( 50vw - 60px - 32px )",
    maxWidth: "50%",
    height: "fit-content",
    padding: "45px",
    boxSizing: "border-box",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "2px dashed #DEE7FA",
  },
});

export const nonAvatarStyle = {
  width: "152px",
  height: "152px",
}
export const avatarStyle = {
  ...nonAvatarStyle,
  borderRadius: "76px",
  boxSizing: "border-box",
  padding: "5px",
  border: "3px solid rgba(68, 214, 44, 1)",
}
export const imageStyle = {
  width: "290px",
  height: "217px",
  padding: "15px",
  boxSizing: "border-box"
}
