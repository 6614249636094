import axios from "./axios";

export const calculateHireCostTrailer = (price, setPricePerDay) => {
  axios
    .get(`/v1/admin/calculate-hire-cost-trailer?price=${price}`)
    .then((response) => {
      return setPricePerDay(response.data.dataObj);
    });
  return;
};
export const calculateHireCostUpsell = (price, setPricePerDay) => {
  axios
    .get(`/v1/admin/calculate-hire-cost-upsell?price=${price}`)
    .then((response) => {
      return setPricePerDay(response.data.dataObj);
    });
  return;
};
