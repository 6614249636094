import React, { useRef, useState, useEffect } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import { Avatar, Box } from "@material-ui/core";
import axios from "../../helpers/axios";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoading";
import axiosInstance from "../../helpers/axios";
import DialogChangeStatus from "../../components/DialogChangeStatus";

function WrapText(props) {
  return <span className="text-14">{props.children}</span>;
}

const EmployeeListInLicensee = () => {
  const { licenseeId, firstname } = useParams();
  const [listEmployeeOfLicensee, setListEmployeeOfLicensee] = useState([]);
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    getAll();
  }, []);
  function getAll() {
    showLoading();
    axios
      .get("/v1/admin/licensee-employee/" + licenseeId)
      .then((response) => {
        hideLoading();
        return setListEmployeeOfLicensee(response.data.userList);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function returnMockData() {
    return [
      {
        title: <span className="title-table">Employees</span>,
        field: "employees",
        render: (rowData) => {
          return (
            <Box display="flex" minWidth="350px" alignItems="center">
              <Avatar
                src={rowData.photo?.data}
                style={{ width: "37px", height: "37px", marginRight: "16px" }}
              />
              <WrapText>{rowData.fullname}</WrapText>
            </Box>
          );
        },
      },
      {
        title: "Email",
        field: "email",
        render: (rowData) => {
          return (
            <div className="list-employee-name">
              <WrapText>{rowData.email}</WrapText>
            </div>
          );
        },
      },
      {
        title: "Permissions",
        field: "permissions",
        render: (rowData) => {
          return (
            <div className="list-employee-item">
              <WrapText>
                {rowData.isAdmin ? "Administrator" : "Standard"}
              </WrapText>
            </div>
          );
        },
      },
      {
        title: "Status",
        field: "status",
        render: (rowData) => {
          return (
            <div
              className={
                rowData.acceptedInvite
                  ? "text-success list-employee-item-to-last"
                  : "text-error list-employee-item-to-last"
              }
            >
              <WrapText>
                {rowData.acceptedInvite ? "Complete" : "Pending"}
              </WrapText>
            </div>
          );
        },
      },
      {
        title: "",
        field: "action",
        render: (rowData) => {
          return (
            <WrapButtonFlexRight>
              <ButtonActionSubPage
                colorError
                minWidth="fit-content"
                padding="8px 10px"
                onClick={() => toggleDialog({ id: rowData._id }, "remove")}
              >
                Remove Employee
              </ButtonActionSubPage>
            </WrapButtonFlexRight>
          );
        },
      },
    ];
  }

  const [isOpen, setOpen] = useState(false);
  const [dataDialog, setDataDialog] = useState({
    isActive: false,
    header: "",
    subtitle: "",
    submitTitle: "",
    onActive: undefined,
  });

  function toggleDialog(data, method) {
    if (method === "remove") {
      setDataDialog({
        isActive: false,
        header:
          "Are you sure you want to remove the employee from the system ?",
        subtitle:
          "The employee will lose access to the app. You can grant them access once any issues are resolved",
        submitTitle: "Remove Employee",
        onActive: () => handleRemove(data.id),
      });
    }
    setOpen(true);
  }
  function handleRemove(id) {
    showLoading();
    axiosInstance
      .delete(`v1/admin/licensee-employee/${id}`)
      .then((response) => {
        hideLoading();
        getAll();
        setOpen(false);

        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  function closeDialog() {
    setOpen(false);
  }
  return (
    <div>
      <WrapHeaderSubPage
        nameHeader={`${firstname}'s Employees`}
      />
      <DialogChangeStatus
        isActive={dataDialog.isActive}
        isOpen={isOpen}
        header={dataDialog.header}
        subtitle={dataDialog.subtitle}
        submitTitle={dataDialog.submitTitle}
        onActive={dataDialog.onActive}
        onCloseDialog={closeDialog}
      />
      <MaterialTable
        className="table-data"
        columns={returnMockData()}
        data={listEmployeeOfLicensee}
        options={{
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          headerStyle: {
            position: "sticky",
            top: 0,
            backgroundColor: "#171717",
          },
          rowStyle: {
            color: "#fff",
          },
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
};
export default EmployeeListInLicensee;
