import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles"
export const ButtonCircle = withStyles({
  root: {
    height: "33px",
    borderRadius: "100px",
  },
  contained: {
    backgroundColor: "#ececec",
    color: "#171717",
  },
  containedPrimary: {
    backgroundColor: "#44D62c",
    color: "#fff",
  },
})((props) => <Button {...props} />)
