import React, { useState } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import { useFormik } from "formik";
import * as yup from "yup";
import { generatePropTextField } from "../../helpers/formik-help";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../hooks/useLoading";
import { ButtonCircle } from "../../components/ButtomCircle";

const ResetPassword = () => {
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  let history = useHistory();
  const { id } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const onSetPassword = async (password) => {
    showLoading();
    try {
      const res = await axios.put(`v1/admin/user-password/${id}`, { password });
      setOpenAlert(true);
      hideLoading();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };
  const formik = useFormik({
    initialValues: { password: "", rePassword: "" },
    validationSchema: yup.object({
      password: yup
        .string()
        .required("At least 8 characters with uppercase, lowercase and number.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .min(8, "At least 8 characters with uppercase, lowercase and number."),
      rePassword: yup
        .string()
        .required("At least 8 characters with uppercase, lowercase and number.")
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .min(8, "At least 8 characters with uppercase, lowercase and number."),
    }),
    onSubmit: (value) => {
      onSetPassword(value.password);
      //handleAddOrUpdateTrailer(value);
    },
  });

  const handleClickExit = () => {
    history.push("/signin");
  };
  return (
    <LayoutAddOrEdit titleDetail="">
      {openAlert && (
        <div className="alert-forgot">
          <div className="text-16">Password successfully updated.</div>
        </div>
      )}
      <div className=" btn-exit-login">
        <ButtonCircle variant="contained" onClick={handleClickExit}>
          Exit
        </ButtonCircle>
      </div>
      <div className="layout-invite">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h3" className="title-send-invite">
            Reset your password
          </Typography>
          <div className="form-signIn">
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("password", formik)}
              id="password"
              name="password"
              label="New Password"
              variant="outlined"
              type="password"
              helperText={formik.touched.password && formik.errors.password}
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("rePassword", formik)}
              id="rePassword"
              name="rePassword"
              label="Confirm password"
              variant="outlined"
              type="password"
              helperText={formik.touched.rePassword && formik.errors.rePassword}
              inputProps={{
                maxLength: 250,
              }}
            />

            <Button
              type="submit"
              className={classes.btnSubmit}
              color="primary"
              variant="contained"
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>
    </LayoutAddOrEdit>
  );
};

export default ResetPassword;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
}));
