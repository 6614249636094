import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const isValidUser =
    user && user !== "undefined" && token && token !== "undefined";
  // console.log(token)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isValidUser) {
          return <Component {...props} />;
        } else {
          localStorage.removeItem("token");
          return <Redirect to={{ pathname: "/signin" }} />;
        }
      }}
    />
  );
};
