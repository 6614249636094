import React, { useEffect, useState } from "react";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import MaterialTable from "material-table";
import { useParams, useLocation } from "react-router-dom";
import WrapButtonFlexRight from "../../components/WrapButtonFlexRight";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import AlertMissingDocument from "../../components/AlertMissingDocument";
import axios from "../../helpers/axios";
import moment from "moment";
import ButtonReturnPreviousPage from "../../components/ButtonReturnPreviousPage"
import { checkIsDocumentExpiry } from "../../helpers/checkIsDocumentExpiry";
import { toast } from "react-toastify";
const convertTimeTo_DD_MM_YY = (time) => {
  return moment(time).format('DD/MM/YY')
}
const CONTENT_ALERT = {
  true: {
    header: "Document Missing",
  },
  7: {
    header: "Document due in 7 days",
    title: "Contact the Licensee if needed",
  }, 
  30: {
    header: "Document due in 30 days",
    title: "Contact the Licensee if needed",
  },
}
const ItemDocumentOfLicensee = () => {
  const [ data, setData ] = useState({
    phone: "",
    list: []
  })
  const [ isExpiry, setExpiry ] = useState(false)
  const paramId = useParams();
  const locationState = useLocation()
  const mockData = [
    {
      field: "trailerType",
      render: (rowData) => {
        return (
          <div style={{ fontSize: 16, lineHeight: "19px"}}>
            {`${locationState?.state?.name} ${convertTimeTo_DD_MM_YY(rowData.timeExpiry)}.${typeOfItemFileDocument(rowData.contentType)}`}
          </div>
        );
      },
    },
    {
      field: "action",
      render: (rowData) => {
        return (
          <WrapButtonFlexRight>
            <ButtonActionSubPage
              href={rowData.data}
            >
              View
            </ButtonActionSubPage>
          </WrapButtonFlexRight>
        );
      },
    },
  ];

  const typeOfItemFileDocument = (type) => {
    return type.split("/")[1].toUpperCase()
  }
  useEffect(() => {
    axios.get(`v1/admin/document-licensee/${paramId.licenseeId}`).then((response) => {
      let newList
      if ( locationState?.state?.keyData ) {
        newList = response.data.licenseeDocObj?.[locationState?.state?.keyData].reverse()
        if ( newList.length !== 0 ) {
          isDocumentExpiry()
        }
        if ( newList.length === 0 ) {
          setExpiry(true)
        }
      }
      setData({ 
        phone: response.data.licenseeDocObj.mobileFormat,
        list:  newList
      })
    })
  },[paramId])

  function isDocumentExpiry() {
    axios.get(`v1/admin/detaillicensee/${paramId.licenseeId}`).then((response) => {
      const keyExpiry = locationState.state.keyExpiry
      if ( keyExpiry !== "proofOfIncorporation" ) {
        const time = response.data.licenseeUserObj.licenseeId[keyExpiry]
        const result = checkIsDocumentExpiry(time)
        return setExpiry(result)
      }
      return
    }).catch((error) => {
      toast.error(error?.response?.data?.message);
    })
  }

  function outputTitleContentAlert(name= "") {
    return `Contact the Licensee to upload a ${name.toLowerCase()} doc on`
  }
  return (
    <>
      <WrapHeaderSubPage nameHeader={locationState?.state?.name}>
        <ButtonReturnPreviousPage />
      </WrapHeaderSubPage>
      <MaterialTable
        className="table-data"
        columns={mockData}
        data={data.list}
        options={{
          header: false,
          toolbar: false,
          showTitle: false,
          draggable: false,
          search: false,
          sorting: false,
          filtering: false,
          paging: false,
          actionsCellStyle: { color: "#fff" },
          cellStyle: {
            padding: "19.5px 0"
          },
          rowStyle: (rowData) => {
            return (
              rowData.tableData.id === 0 && {borderTop: "1px solid #24272d"}
            );
          },
          actionsColumnIndex: -1,
        }}
      />
      {
        locationState.state.keyData !== "proofOfIncorporation" && 
          isExpiry !== false &&
            <AlertMissingDocument 
              header={`${locationState?.state?.name} ${CONTENT_ALERT[isExpiry].header}`} 
              title={outputTitleContentAlert(locationState?.state?.name)}
              contact={data.phone}
            />
      }
    </>
  );
};
export default ItemDocumentOfLicensee;
