import { makeStyles } from "@material-ui/core";
const useCssListItem = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "none",
      color: theme.palette.primary.main,
    },
  },
  text: {
    padding: "10px 20px",
  },
  item: {
    padding: 0,
  },
  button: {
    "&:focus": {
      background: "none",
    },
    "&:hover": {
      backgroundColor: "#171717 !important",
    },
    "&:hover > a": {
      color: theme.palette.primary.main,
    },
  },
  href: {
    fontSize: 14,
    color: "rgba(222, 231, 250, 1)",
    textDecoration: "none",
    padding: "10px 14px",
    width: "100%",
    "&:hover > a": {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    backgroundColor: "#252525",
    marginTop: "5px",
  },
  activeNavLink: {
    color: theme.palette.primary.main
  },
  active: (props) => ({
    color: props.isActive ?  theme.palette.primary.main : "rgba(222, 231, 250, 1)",
  }),
}));
export default useCssListItem;
