import React, { useEffect, useState } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import ButtonActionSubPage from "../../components/ButtonActionSubPage";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import { useFormik } from "formik";
import * as yup from "yup";
import { generatePropTextField } from "../../helpers/formik-help";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { useLoading } from "../../hooks/useLoading";

const SignIn = () => {
  const classes = useStyles();
  const [showLoading, hideLoading] = useLoading();
  let history = useHistory();
  let location = useLocation();
  const [openAlert, setOpenAlert] = useState(location.state?.showSuccess);
  useEffect(() => {
    if (location?.state?.showSuccess) {
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 3000);
      return () => {
        setOpenAlert(false);
      };
    }
  }, [location?.state]);
  const onSignInSubmit = async (email, password) => {
    showLoading();
    try {
      const res = await axios.post("/v1/admin/employee/signin", {
        email,
        password,
      });
      // Response return "data.dataObj" not "data"
      const data = res.data.dataObj;
      localStorage.setItem("user", JSON.stringify(data.employeeObj));
      localStorage.setItem("token", data.token);
      hideLoading();
      history.push("/");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
      //setErrorMessage("Invalid Credentials");
    }
  };
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object({
      email: yup.string().required(" ").email(" "),
      password: yup
        .string()
        .required("At least 8 characters with uppercase, lowercase and number.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .min(8, "At least 8 characters with uppercase, lowercase and number."),
    }),
    onSubmit: (value) => {
      onSignInSubmit(value.email, value.password);
      //handleAddOrUpdateTrailer(value);
    },
  });
  return (
    <LayoutAddOrEdit titleDetail="">
      {openAlert && (
        <div className="alert-forgot">
          <div className="text-16">Password successfully updated.</div>
        </div>
      )}
      <div className="layout-invite">
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h3" className="title-send-invite">
            Sign in to your account
          </Typography>
          <div className="form-signIn">
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("email", formik)}
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              {...generatePropTextField("password", formik)}
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              helperText={formik.touched.password && formik.errors.password}
              inputProps={{
                maxLength: 250,
              }}
            />
            <span
              className="text-16 text-success"
              onClick={() => history.push("/forgot-password")}
              style={{ 
                width: "fit-content"
              }}
            >
              Forgot password<br></br>
            </span>

            <Button
              type="submit"
              className={classes.btnSubmit}
              color="primary"
              variant="contained"
            >
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </LayoutAddOrEdit>
  );
};

export default SignIn;
const useStyles = makeStyles(() => ({
  btnSubmit: {
    width: "303px",
    height: 51,
    marginTop: 40,
  },
}));
