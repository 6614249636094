import React from "react";
import { makeStyles } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: "14px",
    textTransform: "none",
    minWidth: "71px",
    padding: "6px 9px",
  },
}))
const ButtonView = (props) => {
  const classes = useStyles()
  let history = useHistory()
  return (
    <Button
      variant="outlined"
      color="secondary"
      className={classes.root}
      onClick={() => {history.push(props.router)}}
    >
      View
    </Button>
  )
}
export default ButtonView