export const generatePropTextField = (name, formik) => {
  return {
    id: name,
    name: name,
    autoComplete: "off",
    variant: "outlined",
    error: formik.touched[name] && Boolean(formik.errors[name]),
    value: formik.values[name],
    onChange: formik.handleChange,
    inputProps: {
      maxLength: 250,
    },
  };
};

export const resetDefaultForm = (formik) => {
  formik.resetForm();
};
