import { makeStyles } from "@material-ui/core";

const useStyleMenu = makeStyles({
  paper: {
    width: "600px",
    maxHeight: "400px",
    cursor: "pointer",
    "&::-webkit-scrollbar": {
      width: `8px !important`,
    },
    "&::-webkit-scrollbar-thumb": {
      background: `gray !important`,
      borderRadius: `5px !important`,
      "&:hover": {
        background: `gray !important`
      }
    },
  },
  button: {
    "&:focus": {
      background: "none",
    },
    "&:hover:": {
      background: "none",
    },
  },
  text: {
    padding: "0",
  },
  containerButton: {
    "&:hover": {
      background: "none",
    },
  },
  bagde_Root: {
    marginRight: "30px",
  },
  badge_Notification: {
    backgroundColor: "red !important",
    color: "#FFFFFF !important",
  },
});

export default useStyleMenu
