import React, { useState } from "react";
import { Menu, Button, MenuItem } from "@material-ui/core";
import { NavLink, useLocation } from "react-router-dom";
import useCssListItem from "./style";
const ButtonMenuNavlink = ({ name, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cssListItem = useCssListItem({
    isActive: options.some((item) => location.pathname.includes(item.link)),
  });
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        disableFocusRipple
        disableTouchRipple
        classes={{
          root: `
            ${cssListItem.root}
            ${cssListItem.active}
          `,
          text: cssListItem.text,
        }}
        onClick={handleClick}
      >
        {name}
      </Button>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: cssListItem.paper,
        }}
        MenuListProps={{
          disablePadding: true,
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
            ListItemClasses={{
              root: cssListItem.item,
              button: cssListItem.button,
            }}
          >
            <NavLink
              to={item.link} 
              className={cssListItem.href}
              activeClassName={cssListItem.activeNavLink}
            >
              {item.name}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonMenuNavlink;
