import React from "react";
import { Box, makeStyles } from "@material-ui/core";
export const FormDataStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "calc( 50vw -60px )",
    maxWidth: "50%",
    display: "flex",
    flexDirection: "column",
    padding: "0 34px 0 100px",
  },
});
const WrapFormItemLicensee = ({ formik, children }) => {
  const cssFormData = FormDataStyles();
  return (
    <Box classes={cssFormData} onSubmit={formik.handleSubmit} component="form">
      {children}
    </Box>
  );
};
export default WrapFormItemLicensee;
