import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useFormik } from "formik";
import TemplateImageUser from "../../components/TemplateImageUser";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import * as yup from "yup";
import {
  generatePropTextField,
} from "../../helpers/formik-help";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import { useLoading } from "../../hooks/useLoading";
import axiosInstance from "../../helpers/axios";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonActionHeader from "../../components/ButtonActionHeader";

const AddOrEditAdminUser = () => {
  const [image, setImage] = useState({
    url: "",
    file: "",
  });

  const [userData, setUserData] = useState({ name: "", email: "" });
  const { id } = useParams();
  const location = useLocation();
  let history = useHistory();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    if (location.state?.detail) {
      setImage({ url: location.state?.detail?.photos?.data, file: "" });
      setUserData(location.state.detail);
    }
    return () => {};
  }, [location.state?.detail]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData,
    validationSchema: yup.object({
      name: yup.string().required(" "),
      email: yup.string().required(" ").email(" "),
    }),
    onSubmit: (value) => {
      handleAddUser(value);
    },
  });

  const handleAddUser = async (user) => {
    showLoading();
    try {
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("email", user.email);

      if (image.file) {
        formData.append("photos", image.file);
      }

      if (location.state?.detail) {
        const res = await axiosInstance.put(
          "v1/admin/user/" + location.state?.detail._id,
          formData
        );
        toast.success(res?.data?.message);
      } else {
        const res = await axiosInstance.post("v1/admin/user/", formData);
        toast.success(res?.data?.message);
      }

      hideLoading();
      history.push("/admin");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <Box height="100%" paddingBottom="85px">
      <WrapHeaderSubPage
        nameHeader={location.state?.detail ? "Edit User" : "New User"}
      />
      <Box display="flex">
        <TemplateImageUser onAddImage={setImage} dataImage={image} />
        <WrapFormItemLicensee formik={formik}>
          <FieldFormAdd
            {...generatePropTextField("name", formik)}
            label="Name"
          />
          <FieldFormAdd
            {...generatePropTextField("email", formik)}
            label="Email"
          />
          <WrapButtonSubmitTrailer>
            <ButtonActionHeader 
              width="233px" onClick={() => formik.handleSubmit()}
            >
              {location.state?.detail ? "Update User" : "Add User"}
            </ButtonActionHeader>
          </WrapButtonSubmitTrailer>
        </WrapFormItemLicensee>
      </Box>
    </Box>
  );
};
export default AddOrEditAdminUser;
