const NAVLINK_LIST = [
  {
    id: "featured-menu",
    name: "Featured",
    link: "/",
  },
  {
    id: "customer-menu",
    name: "Customer",
    link: "/customers",
  },
  {
    id: "licensee",
    name: "Licensee",
    link: "/licensee",
  },
  {
    id: "rental-item-menu",
    name: "Rental Item",
    link: "/rental",
    options: [
      {
        name: "Trailers",
        link: "/rental/trailer",
      },
      {
        name: "Upsell Items",
        link: "/rental/upsell",
      },
    ],
  },
  {
    id: "employee-menu",
    name: "Admin Users",
    link: "/admin",
  },
  {
    id: "transaction-history",
    name: "Transaction History",
    link: "/transaction-history",
  },
];

export default NAVLINK_LIST