import React, { useState, useEffect } from "react";
import { ButtonCircle } from "../../components/ButtomCircle";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit/index";
import SwitchCus from "../../components/SwitchCus";
import { FieldFormAdd } from "../../components/FieldFormAdd";
import AlertPopup from "../../components/AlertPopup";
import {
  Typography,
  TextField,
  makeStyles,
  MobileStepper,
  Button,
} from "@material-ui/core";
import { common } from "../../constants/common";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as Subtract } from "../../img/subtract.svg";
import TextFieldMask from "../../components/TextFieldMask/index";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { CustomTextField } from "../../components/SelectField";
import { toast } from "react-toastify";
import SearchPlace from "../../components/SearchPlace/SearchPlace";
import {
  isMobilePhone,
  isFullDate,
  isTime,
  isImgFile,
  checkTimeStartEnd,
} from "../../helpers/verify";
import ItemAddTrailers from "../../components/ItemAddTrailer";
import moment from "moment";
import { formatDriverNumber, formatMobileNumber } from "../../helpers/format";
const listValidate = [
  yup.object({
    firstName: yup.string().required(" "),
    lastName: yup.string().required(" "),
    email: yup.string().required(" ").email(" "),
    phone: yup.string().required(" "),
    businessType: yup.string().required(" "),
  }),
  yup.object({
    licenseNumber: yup.string().min(11, " ").required(" "),
    expiryDate: yup.string().min(8, " ").required(" "),
    state: yup.string().required(" "),
  }),
  yup.object({
    registrationExpiry: yup.string().min(10, " ").required(" "),
    insuranceExpiry: yup.string().min(10, " ").required(" "),
  }),
  yup.object({
    aptEtc: yup.string(),
    radius: yup.number().required(" "),
  }),
  yup.object({}),
];

const LicenseeEdit = () => {
  const classes = useStyles();
  let history = useHistory();
  const [activeStep, setActiveStep] = React.useState(1);
  const [titleDetail, setTitleDetail] = useState(detailsStep[0]);
  const [trailerAddList, setTrailerAddList] = useState([]);
  const [trailerList, setTrailerList] = useState([]);
  const [trailerListTemp, setTrailerListTemp] = useState([]);
  const [upsellList, setUpsellList] = useState([]);
  const [upsellListTemp, setUpsellListTemp] = useState([]);
  const [upsellAddList, setUpsellAddList] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [photoOfCus, setPhotoOfCus] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [registrationExpiry, setRegistrationExpiry] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [insuranceExpiry, setInsuranceExpiry] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [businessLogo, setBusinessLogo] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [driversLicense, setDriversLicense] = useState({
    status: true,
    file: null,
    files: null,
    link1: null,
    link2: null,
  });

  const [frontDriver, setFrontDriver] = useState({
    status: true,
    file: null,
    ink: null,
  });
  const [backDriver, setBackDriver] = useState({
    status: true,
    file: null,
    ink: null,
  });
  const [proof, setProof] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [addDocs, setAddDocs] = useState({
    status: true,
    file: null,
    link: null,
  });
  const [location, setLocation] = useState({ name: "" });
  const [errorLocation, setErrorLocation] = useState(false);
  const [licensee, setLicensee] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessType: "",
    licenseNumber: "",
    expiryDate: "",
    state: "",
    aptEtc: "",
    city: "",
    suburb: "",
    stateWork: "",
    postCode: "",
    radius: "",
    registrationExpiry: "",
    insuranceExpiry: "",
  });
  const [workingHours, setWorkingHours] = useState([
    {
      id: 1,
      day: "Sunday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 2,
      day: "Monday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 3,
      day: "Tuesday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 4,
      day: "Wednesday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 5,
      day: "Thursday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 6,
      day: "Friday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
    {
      id: 7,
      day: "Saturday",
      isOpen: false,
      timeOpen: "07:00",
      timeClose: "18:00",
      errorOpen: false,
      errorClose: false,
    },
  ]);
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      axios.get(`v1/admin/detaillicensee/${id}`).then((response) => {
        let currentLicensee = response.data.licenseeUserObj;
        setLicensee({
          firstName: currentLicensee.firstname,
          lastName: currentLicensee.lastname,
          email: currentLicensee.email,
          phone: currentLicensee.mobile.replace("+61", 0).replace("+84", 0),
          businessType: currentLicensee.licenseeId.businessType,
          licenseNumber: formatDriverNumber(currentLicensee.driverNumber),
          expiryDate: moment(currentLicensee.expiryDate).format("DD/MM/YYYY"),
          state: currentLicensee.state.toLowerCase(),
          aptEtc: currentLicensee.licenseeId?.address?.apt,
          suburb: currentLicensee.licenseeId?.address?.suburb,
          city: currentLicensee.licenseeId?.address?.city,
          stateWork:
            currentLicensee.licenseeId?.address?.stateAddress?.toLowerCase(),
          postCode: currentLicensee.licenseeId?.address?.postCode,
          radius: currentLicensee.licenseeId?.address?.radius,
          registrationExpiry: moment(
            currentLicensee.licenseeId.businessRegistrationExpiry
          ).format("DD/MM/YYYY"),
          insuranceExpiry: moment(
            currentLicensee.licenseeId.businessInsuranceExpiry
          ).format("DD/MM/YYYY"),
        });
        setLocation({
          ...location,
          name: currentLicensee.licenseeId?.address?.streetAddress,
          geometry: {
            location: {
              lat: currentLicensee.licenseeId?.address?.location
                ?.coordinates[1],
              lng: currentLicensee.licenseeId?.address?.location
                ?.coordinates[0],
            },
          },
        });
        setPhotoOfCus({
          ...photoOfCus,
          link: currentLicensee.photo?.data,
        });
        setBusinessLogo({
          ...businessLogo,
          link: currentLicensee.licenseeId.businessLogo?.data,
        });
        setDriversLicense({
          ...driversLicense,
          link1: currentLicensee.driversOfLicensee[0]?.data,
          link2: currentLicensee.driversOfLicensee[1]?.data,
        });
        setFrontDriver({
          ...frontDriver,
          link:
            currentLicensee.driversOfLicensee.find(
              (item) => item.type === "front"
            )?.data || null,
        });
        setBackDriver({
          ...backDriver,
          link:
            currentLicensee.driversOfLicensee.find(
              (item) => item.type === "behind"
            )?.data || null,
        });
        setProof({
          ...proof,
          link: currentLicensee.licenseeId?.proofOfIncorporation?.data,
        });
        setAddDocs({
          ...addDocs,
          link: currentLicensee.licenseeId?.additionalDocs?.data,
        });
        setRegistrationExpiry({
          ...registrationExpiry,
          link: currentLicensee.licenseeId?.businessRegistration?.data,
        });
        setInsuranceExpiry({
          ...registrationExpiry,
          link: currentLicensee.licenseeId?.businessInsurance?.data,
        });
        let tempWorkingHours = [...workingHours];
        for (let item of currentLicensee.licenseeId.workingDays) {
          let index = tempWorkingHours.findIndex(
            (day) => day.day.toLowerCase() === item.day.toLowerCase()
          );
          tempWorkingHours[index] = {
            ...tempWorkingHours[index],
            isOpen: item.isOpen,
            timeOpen: item.startTime,
            timeClose: item.closeTime,
          };
        }
        setWorkingHours(tempWorkingHours);
        axios.get("/v1/admin/get-all-upsellitems").then((response) => {
          let upsells = response.data.dataObj.map((item) => {
            return { quantity: 0, upsell: item };
          });

          for (let item of currentLicensee.licenseeId.upsellItems) {
            let index = upsells.findIndex(
              (upsell) => item.upsellItemId === upsell.upsell._id
            );
            upsells[index] = {
              ...upsells[index],
              quantity: upsells[index].quantity + 1,
            };
          }
          setUpsellList(upsells);
          setUpsellListTemp(
            upsells.map((item) => {
              return Object.assign({}, item);
            })
          );
        });

        axios.get("/v1/admin/get-all-trailers").then((response) => {
          let trailers = response.data.dataObj.map((item) => {
            return { quantity: 0, trailer: item };
          });
          for (let item of currentLicensee.licenseeId.trailers) {
            let index = trailers.findIndex(
              (trailer) => item.trailerId === trailer.trailer._id
            );
            trailers[index] = {
              ...trailers[index],
              quantity: trailers[index].quantity + 1,
            };
          }
          setTrailerListTemp(
            trailers.map((item) => {
              return Object.assign({}, item);
            })
          );
          setTrailerList(trailers);
        });
      });
    }
  }, [id]);
  const validateWorkingHour = (workingHoursState) => {
    const workingHours = [...workingHoursState];
    for (let item of workingHours) {
      if (item.isOpen) {
        if (!isTime(item.timeOpen) || !isTime(item.timeClose)) {
          if (!isTime(item.timeOpen)) {
            item.errorOpen = true;
          }
          if (!isTime(item.timeClose)) {
            item.errorClose = true;
          }
          setWorkingHours(workingHours);
          return false;
        }
        if (!checkTimeStartEnd(item.timeOpen, item.timeClose)) {
          item.errorClose = true;
          setWorkingHours(workingHours);
          return false;
        }
      }
    }
    return workingHours.some((item) => item.isOpen);
  };
  const checkListTrailer = () => {
    const check = trailerList.some((trailer) => {
      return trailer.quantity > 0;
    });
    if (!check) {
      setOpenAlert(true);
      return false;
    }
    return true;
  };

  const formik = useFormik({
    initialValues: licensee,
    enableReinitialize: true,
    validationSchema: listValidate[activeStep - 1],
    onSubmit: (value) => {
      if (activeStep === 1) {
        if (!isMobilePhone(value.phone)) {
          return false;
        }
        if (!photoOfCus.file && !photoOfCus.link) {
          return setPhotoOfCus({ ...photoOfCus, status: false });
        } else {
          setPhotoOfCus({ ...photoOfCus, status: true });
        }
        if (
          value.businessType === "company" &&
          !businessLogo.file &&
          !businessLogo.link
        ) {
          return setBusinessLogo({ ...businessLogo, status: false });
        } else {
          setBusinessLogo({ ...businessLogo, status: true });
        }
      }
      if (activeStep === 2) {
        if (!isFullDate(value.expiryDate)) {
          return false;
        }
      }
      if (activeStep === 3) {
        if (
          !isFullDate(value.registrationExpiry) ||
          !isFullDate(value.insuranceExpiry)
        ) {
          return false;
        }
      }

      if (activeStep === 4 && !location.name) {
        return setErrorLocation(true);
      }
      if (activeStep === 5) {
        if (!validateWorkingHour(workingHours)) {
          return false;
        }
      }

      if (activeStep === 6) {
        if (!checkListTrailer()) {
          return false;
        }
      }

      setLicensee({ ...licensee, ...value });

      handleNext();
    },
  });
  const handleClickExit = () => {
    history.push("/licensee");
  };
  const handleChangeQuantity = (id, method, type) => {
    if (type === "upsell") {
      const temp = [...upsellList];
      for (let item of temp) {
        if (item.upsell._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }
      let temp2 = [...upsellAddList];
      if (method === "subtract") {
        const index = temp2.findIndex((item) => item.upsellItemId === id);
        temp2.splice(index, 1);
      } else {
        temp2.push({
          upsellItemId: id,
        });
      }
      setUpsellAddList(temp2);
      setUpsellList(temp);
      return;
    }
    if (type === "trailer") {
      const temp = [...trailerList];

      for (let item of temp) {
        if (item.trailer._id === id) {
          if (method === "subtract") {
            item.quantity = item.quantity - 1;
          } else {
            item.quantity = item.quantity + 1;
          }
        }
      }

      let temp2 = [...trailerAddList];
      if (method === "subtract") {
        const index = temp2.findIndex((item) => item.trailerId === id);
        temp2.splice(index, 1);
      } else {
        temp2.push({
          trailerId: id,
          registration: null,
          expiryRegistration: null,
        });
      }
      setTrailerAddList(temp2);
      setTrailerList(temp);
    }
    setOpenAlert(false);
  };
  const handleNext = () => {
    if (activeStep < 7) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setTitleDetail(detailsStep[activeStep]);
    } else {
      handleSaveLicensee();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTitleDetail(detailsStep[activeStep - 2]);
  };
  const handleChangeWorking = (id) => {
    const temp = workingHours.map((item) => {
      if (item.id === id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return item;
      }
    });
    setWorkingHours(temp);
  };
  const handleChangeWorkingHour = (e, id, type) => {
    const temp = workingHours.map((item) => {
      if (item.id === id) {
        item.errorClose = false;
        item.errorOpen = false;
        if (type === "open") {
          if (isTime(e.target.value)) {
            return { ...item, timeOpen: e.target.value, errorOpen: false };
          }
          return { ...item, timeOpen: e.target.value, errorOpen: true };
        } else {
          if (isTime(e.target.value)) {
            return { ...item, timeClose: e.target.value, errorClose: false };
          }
          return { ...item, timeClose: e.target.value, errorClose: true };
        }
      } else {
        return item;
      }
    });
    setWorkingHours(temp);
  };
  const renderForm = (activeStep) => {
    switch (activeStep) {
      case 1: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="form-input">
              <FieldFormAdd
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="email"
                name="email"
                label="Email Address"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                fullWidth
                id="phone"
                name="phone"
                label="Phone Number"
                variant="outlined"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={
                  (formik.touched.phone && Boolean(formik.errors.phone)) ||
                  !isMobilePhone(formik.values.phone)
                }
                inputProps={{
                  maxLength: 250,
                }}
              />
              <CustomTextField
                name="businessType"
                id="businessType"
                label="Business type"
                value={formik.values.businessType}
                onChange={formik.handleChange}
                error={
                  formik.touched.businessType &&
                  Boolean(formik.errors.businessType)
                }
                options={common.businessType}
                classMenuItem={classes.menuItem}
              />
              <div className="main-media">
                <input
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  className={classes.input}
                  id="contained-button-file1"
                  multiple
                  type="file"
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setPhotoOfCus({
                        ...photoOfCus,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else {
                      setPhotoOfCus({
                        ...photoOfCus,
                        file: null,
                        // status: false,
                      });
                    }
                  }}
                />
                <label htmlFor="contained-button-file1">
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${classes.btnMedia} ${
                      !photoOfCus.status ? classes.btnError : null
                    }`}
                  >
                    Add Photo of Licensee
                  </ButtonCircle>
                  <div className="file-name">
                    {photoOfCus.file
                      ? photoOfCus.file?.name.slice(-20)
                      : photoOfCus.link?.slice(-17)}
                  </div>
                </label>

                <input
                  className={classes.input}
                  id="contained-button-file2"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setBusinessLogo({
                        ...businessLogo,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (businessLogo.file) {
                      setBusinessLogo({ ...businessLogo, status: true });
                    } else {
                      setBusinessLogo({
                        ...businessLogo,
                        file: null,
                        // status: false,
                      });
                    }
                  }}
                />
                <label htmlFor="contained-button-file2">
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${classes.btnMedia} ${
                      !businessLogo.status ? classes.btnError : null
                    }`}
                  >
                    Add Business Logo
                  </ButtonCircle>
                  <div className="file-name">
                    {businessLogo.file
                      ? businessLogo.file?.name.slice(-20)
                      : businessLogo.link?.slice(-17)}
                  </div>
                </label>
              </div>
            </div>
          </>
        );
      }
      case 2: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="form-input">
              <TextFieldMask
                formatChars={common.formatNumber}
                mask="999 999 999"
                inputProps={{
                  maxLength: 11,
                }}
                value={formik.values.licenseNumber}
                onChange={formik.handleChange}
                id="licenseNumber"
                name="licenseNumber"
                label="Driver license number"
              >
                {(inputProps) => (
                  <FieldFormAdd
                    fullWidth
                    variant="outlined"
                    error={
                      formik.touched.licenseNumber &&
                      Boolean(formik.errors.licenseNumber)
                    }
                    {...inputProps}
                  />
                )}
              </TextFieldMask>
              <TextFieldMask
                formatChars={common.formatDate}
                mask="32/12/2222"
                value={formik.values.expiryDate}
                onChange={formik.handleChange}
                error={
                  (formik.touched.expiryDate &&
                    Boolean(formik.errors.expiryDate)) ||
                  !isFullDate(formik.values.expiryDate)
                }
                inputProps={{
                  maxLength: 250,
                }}
                id="expiryDate"
                name="expiryDate"
                label="Expiry date"
              >
                {(inputProps) => (
                  <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
                )}
              </TextFieldMask>
              <CustomTextField
                name="state"
                id="state"
                label="State"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                options={common.states}
                classMenuItem={classes.menuItem}
              />
              <div className="main-media">
                <input
                  className={classes.input}
                  id="contained-button-file5"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setFrontDriver({
                        ...frontDriver,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (frontDriver.file) {
                      setFrontDriver({ ...frontDriver, status: true });
                    } else {
                      setFrontDriver({
                        ...frontDriver,
                        file: null,
                        status: true,
                      });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file5"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Front of drivers license
                  </ButtonCircle>
                  <div className="file-name">
                    {frontDriver.file
                      ? frontDriver.file?.name.slice(-20)
                      : frontDriver.link?.slice(-17)}
                  </div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file6"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setBackDriver({
                        ...backDriver,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (backDriver.file) {
                      setBackDriver({ ...backDriver, status: true });
                    } else {
                      setBackDriver({
                        ...backDriver,
                        file: null,
                        status: true,
                      });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file6"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Back of drivers license
                  </ButtonCircle>
                  <div className="file-name">
                    {backDriver.file
                      ? backDriver.file?.name.slice(-20)
                      : backDriver.link?.slice(-17)}
                  </div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file2"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setProof({
                        ...proof,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else {
                      setProof({ ...proof, file: null, status: true });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file2"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                    className={`${!proof.status ? classes.btnError : null}`}
                  >
                    Proof of incorporation
                  </ButtonCircle>
                  <div className="file-name">
                    {proof.file
                      ? proof.file?.name.slice(-20)
                      : proof.link?.slice(-17)}
                  </div>
                </label>
                <input
                  className={classes.input}
                  id="contained-button-file3"
                  multiple
                  type="file"
                  accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                  onChange={(e) => {
                    if (
                      e.target.files.length > 0 &&
                      isImgFile(e.target.files[0])
                    ) {
                      setAddDocs({
                        ...addDocs,
                        status: true,
                        file: e.target.files[0],
                      });
                    } else if (addDocs.file) {
                      setAddDocs({ ...addDocs, status: true });
                    } else {
                      setAddDocs({ ...addDocs, file: null, status: true });
                    }
                  }}
                />
                <label
                  htmlFor="contained-button-file3"
                  className={classes.btnMediaLicense}
                >
                  <ButtonCircle
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Additional Docs
                  </ButtonCircle>
                  <div className="file-name">
                    {addDocs.file
                      ? addDocs.file?.name.slice(-20)
                      : addDocs.link?.slice(-17)}
                  </div>
                </label>
              </div>
            </div>
          </>
        );
      }
      case 3: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>

            <TextFieldMask
              formatChars={common.formatDate}
              mask="32/12/2222"
              value={formik.values.registrationExpiry}
              onChange={formik.handleChange}
              error={
                (formik.touched.registrationExpiry &&
                  Boolean(formik.errors.registrationExpiry)) ||
                !isFullDate(formik.values.registrationExpiry)
              }
              inputProps={{
                maxLength: 250,
              }}
              id="registrationExpiry"
              name="registrationExpiry"
              label="Registration Expiry"
            >
              {(inputProps) => (
                <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
              )}
            </TextFieldMask>
            <TextFieldMask
              formatChars={common.formatDate}
              mask="32/12/2222"
              id="insuranceExpiry"
              name="insuranceExpiry"
              label="Insurance Expiry"
              variant="outlined"
              value={formik.values.insuranceExpiry}
              onChange={formik.handleChange}
              error={
                (formik.touched.insuranceExpiry &&
                  Boolean(formik.errors.insuranceExpiry)) ||
                !isFullDate(formik.values.insuranceExpiry)
              }
              inputProps={{
                maxLength: 250,
              }}
            >
              {(inputProps) => (
                <FieldFormAdd fullWidth variant="outlined" {...inputProps} />
              )}
            </TextFieldMask>

            <div className="main-media">
              <input
                accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                className={classes.input}
                id="contained-button-file10"
                multiple
                type="file"
                onChange={(e) => {
                  if (
                    e.target.files.length > 0 &&
                    isImgFile(e.target.files[0])
                  ) {
                    setRegistrationExpiry({
                      ...registrationExpiry,
                      status: true,
                      file: e.target.files[0],
                    });
                  } else {
                    setRegistrationExpiry({
                      ...registrationExpiry,
                      file: null,
                      status: true,
                    });
                  }
                }}
              />
              <label htmlFor="contained-button-file10">
                <ButtonCircle
                  variant="contained"
                  color="primary"
                  component="span"
                  className={`${classes.btnMedia} ${
                    !registrationExpiry.status ? classes.btnError : null
                  }`}
                >
                  Business registration
                </ButtonCircle>
                <div className="file-name">
                  {registrationExpiry.file
                    ? registrationExpiry.file?.name.slice(-20)
                    : registrationExpiry.link?.slice(-17)}
                </div>
              </label>

              <input
                className={classes.input}
                id="contained-button-file20"
                multiple
                type="file"
                accept="application/pdf, image/jpg, image/png, .jpg, .jpeg, .heic, .bmb, .gif "
                onChange={(e) => {
                  if (
                    e.target.files.length > 0 &&
                    isImgFile(e.target.files[0])
                  ) {
                    setInsuranceExpiry({
                      ...insuranceExpiry,
                      status: true,
                      file: e.target.files[0],
                    });
                  } else {
                    setInsuranceExpiry({
                      ...insuranceExpiry,
                      file: null,
                      status: true,
                    });
                  }
                }}
              />
              <label htmlFor="contained-button-file20">
                <ButtonCircle
                  variant="contained"
                  color="primary"
                  component="span"
                  className={`${classes.btnMedia} ${
                    !insuranceExpiry.status ? classes.btnError : null
                  }`}
                >
                  Business insurance
                </ButtonCircle>
                <div className="file-name">
                  {insuranceExpiry.file
                    ? insuranceExpiry.file?.name.slice(-20)
                    : insuranceExpiry.link?.slice(-17)}
                </div>
              </label>
            </div>
          </>
        );
      }
      case 4: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <input type="hidden" autoComplete="chrome-off" />
            <SearchPlace
              title="Street address"
              location={location}
              setLocation={setLocation}
              errorLocation={errorLocation}
              setErrorLocation={setErrorLocation}
              licensee={licensee}
              setLicensee={setLicensee}
            />
            <FieldFormAdd
              fullWidth
              id="aptEtc"
              name="aptEtc"
              label="Apt, suite, etc (Optional)"
              variant="outlined"
              value={formik.values.aptEtc}
              onChange={formik.handleChange}
              error={formik.touched.aptEtc && Boolean(formik.errors.aptEtc)}
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              disabled
              id="suburb"
              name="suburb"
              label="Suburb"
              variant="outlined"
              autoComplete="off"
              value={formik.values.suburb}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 250,
              }}
            />
            <FieldFormAdd
              fullWidth
              disabled
              id="city"
              name="city"
              label="City"
              variant="outlined"
              autoComplete="off"
              value={formik.values.city}
              onChange={formik.handleChange}
              inputProps={{
                maxLength: 250,
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FieldFormAdd
                disabled
                id="stateWork"
                name="stateWork"
                label="State"
                variant="outlined"
                className={classes.input50}
                value={formik.values.stateWork}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <FieldFormAdd
                disabled  
                id="postCode"
                name="postCode"
                label="Post Code"
                variant="outlined"
                className={classes.input50}
                value={formik.values.postCode}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: 250,
                }}
              />
            </div>
            <FieldFormAdd
              fullWidth
              id="radius"
              name="radius"
              label="Radius for Trailer Drop off (Km)"
              variant="outlined"
              value={formik.values.radius}
              onChange={formik.handleChange}
              error={formik.touched.radius && Boolean(formik.errors.radius)}
              inputProps={{
                maxLength: 250,
              }}
            />
          </>
        );
      }
      case 5: {
        return (
          <>
            <Typography variant="h3" className={classes.titleWorking}>
              {titleDetail.subTitle}
            </Typography>
            {workingHours.map((item, index) => {
              return (
                <div key={item.id} className="selectWorkingHour">
                  <div>
                    <Typography variant="h4" className={classes.titleDay}>
                      {item.day}
                    </Typography>
                    <SwitchCus
                      checked={item.isOpen}
                      onChange={() => handleChangeWorking(item.id)}
                      text="Open"
                    />
                  </div>

                  <div
                    className={
                      item.isOpen ? classes.formTimeHour : classes.hidden
                    }
                  >
                    <TextFieldMask
                      formatChars={common.formatTime}
                      mask="12:34"
                      placeholder="Opens at"
                      className={classes.inputTime}
                      value={item.timeOpen}
                      onChange={(e) =>
                        handleChangeWorkingHour(e, item.id, "open")
                      }
                      error={item.errorOpen}
                    >
                      {(inputProps) => (
                        <TextField variant="outlined" {...inputProps} />
                      )}
                    </TextFieldMask>

                    <Subtract className={classes.subtract} />
                    <TextFieldMask
                      formatChars={common.formatTime}
                      mask="12:34"
                      placeholder="Closes at"
                      className={classes.inputTime}
                      value={item.timeClose}
                      onChange={(e) =>
                        handleChangeWorkingHour(e, item.id, "close")
                      }
                      error={item.errorClose}
                    >
                      {(inputProps) => (
                        <TextField variant="outlined" {...inputProps} />
                      )}
                    </TextFieldMask>
                  </div>
                </div>
              );
            })}
          </>
        );
      }
      case 6: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="list-add-trailer">
              {trailerList.map((item, index) => (
                <ItemAddTrailers
                  key={index}
                  min={trailerListTemp[index].quantity}
                  name={item.trailer.name}
                  urlImage={item.trailer.photos[0]?.url}
                  quantity={item.quantity}
                  handleSubQuantity={() =>
                    handleChangeQuantity(
                      item.trailer._id,
                      "subtract",
                      "trailer"
                    )
                  }
                  handleAddQuantity={() =>
                    handleChangeQuantity(item.trailer._id, "add", "trailer")
                  }
                />
              ))}
            </div>
          </>
        );
      }
      case 7: {
        return (
          <>
            <Typography variant="h3">{titleDetail.subTitle}</Typography>
            <div className="list-add-trailer">
              {upsellList.map((item, index) => (
                <ItemAddTrailers
                  key={index}
                  min={upsellListTemp[index].quantity}
                  name={item.upsell.name}
                  urlImage={item.upsell.photos[0]?.url}
                  quantity={item.quantity}
                  handleSubQuantity={() =>
                    handleChangeQuantity(item.upsell._id, "subtract", "upsell")
                  }
                  handleAddQuantity={() =>
                    handleChangeQuantity(item.upsell._id, "add", "upsell")
                  }
                />
              ))}
            </div>
          </>
        );
      }
    }
  };

  const [showLoading, hideLoading] = useLoading();
  const handleSaveLicensee = async () => {
    showLoading();
    try {
      const workingHoursTemp = workingHours.map((item) => {
        return {
          day: item.day.toLowerCase(),
          startTime: item.timeOpen,
          closeTime: item.timeClose,
          isOpen: item.isOpen,
        };
      });

      const formData = new FormData();
      formData.append("firstname", licensee.firstName);
      formData.append("lastname", licensee.lastName);
      formData.append("mobile", formatMobileNumber(licensee.phone));
      formData.append("businessType", licensee.businessType);
      formData.append("driverNumber", licensee.licenseNumber.replace(/ /g, ""));
      formData.append("expiryDate", licensee.expiryDate);
      formData.append("state", licensee.state);
      formData.append("streetAddress", location.name);
      formData.append("postCode", licensee.postCode);
      formData.append("radius", licensee.radius);
      formData.append("city", licensee.city);
      formData.append("suburb", licensee.suburb);
      formData.append(
        "businessRegistrationExpiry",
        licensee.registrationExpiry
      );
      formData.append("businessInsuranceExpiry", licensee.insuranceExpiry);
      if (licensee.aptEtc) {
        formData.append("apt", licensee.aptEtc);
      }

      formData.append("stateAddress", licensee.stateWork);

      for (let item of workingHoursTemp) {
        formData.append("workingDays[]", JSON.stringify(item));
      }

      for (let item of trailerAddList) {
        formData.append("trailers[]", JSON.stringify(item));
      }

      for (let item of upsellAddList) {
        formData.append("upsellItems[]", JSON.stringify(item));
      }

      formData.append("inviteEmail", licensee.email);
      formData.append("coordinates[]", location.geometry.location.lng);
      formData.append("coordinates[]", location.geometry.location.lat);

      if (photoOfCus.file) {
        formData.append(
          "photoCustomer",
          photoOfCus.file,
          photoOfCus.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      if (insuranceExpiry.file) {
        formData.append(
          "businessInsurance",
          insuranceExpiry.file,
          `Business_Insurance_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (registrationExpiry.file) {
        formData.append(
          "businessRegistration",
          registrationExpiry.file,
          `Business_Registration_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (businessLogo.file) {
        formData.append(
          "businessLogo",
          businessLogo.file,
          businessLogo.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      if (frontDriver.status && frontDriver.file) {
        formData.append(
          "driversOfLicensee",
          frontDriver.file,
          frontDriver.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      if (backDriver.status && backDriver.file) {
        formData.append(
          "driversOfLicensee",
          backDriver.file,
          backDriver.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }

      // if (driversLicense.file) {
      //   formData.append(
      //     "driversOfLicensee",
      //     driversLicense.files[0],
      //     driversLicense.files[0].name.replaceAll(" ", "").replaceAll("/", "")
      //   );
      //   formData.append(
      //     "driversOfLicensee",
      //     driversLicense.files[1],
      //     driversLicense.files[1].name.replaceAll(" ", "").replaceAll("/", "")
      //   );
      // }
      if (proof.file) {
        formData.append(
          "proofOfIncorporation",
          proof.file,
          `Proof_Of_Incorporation_${moment().format("DD_MM_YY").toString()}`
        );
      }
      if (addDocs.file) {
        formData.append(
          "additionalDocs",
          addDocs.file,
          addDocs.file.name.replaceAll(" ", "").replaceAll("/", "")
        );
      }
      const res = await axios.put(`/v1/admin/licensee/${id}`, formData);
      toast.success(res?.data?.message);
      hideLoading();
      history.push("/licensee");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <LayoutAddOrEdit titleDetail={titleDetail.title}>
      <div className="btn-exit">
        <ButtonCircle variant="contained" onClick={handleClickExit}>
          Exit
        </ButtonCircle>
      </div>
      <form onSubmit={formik.handleSubmit} autoComplete="chrome-off">
        <div className="form-main">
          {renderForm(activeStep)}
          <div className="alert">
            <AlertPopup
              title="Please select at least one trailer from the list"
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
            />
          </div>

          <div className="footter-process">
            <div className="step-process">
              <MobileStepper
                variant="progress"
                steps={8}
                position="static"
                activeStep={activeStep}
                className={classes.process}
              />
            </div>
            <div className="btn-process">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                className={activeStep === 1 ? classes.hidden : classes.btnStep}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnStep}
                type="submit"
              >
                {activeStep === 7 ? "Save" : "Next"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </LayoutAddOrEdit>
  );
};

export default LicenseeEdit;

export const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: "40px",
    padding: "12px 16px",
    fontSize: "14px",
    boxShadow: "inset 0px -1px 0px #ECF1FC",
    "&:last-child": {
      boxShadow: "none",
    },
  },
  btnStep: {
    width: "97px",
  },
  input50: {
    width: "calc( (50vw - 188px ) / 2 - 8px)",
    "& .MuiSelect-iconOutlined": {
      right: "15px",
    },
  },

  subtract: {
    fontSize: "18px",
    width: "18px",
    height: "2px",
  },
  btnMedia: {
    width: "187px",
    marginRight: "18px",
  },
  btnError: {
    backgroundColor: "#FF0000",
  },
  btnMediaLicense: {
    marginRight: "7px",
  },
  titleWorking: {
    marginBottom: "45px!important",
  },

  titleDay: {
    width: "126px",
    marginRight: "24px",
  },
  inputTime: {
    margin: "0 12px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
    },
    "input::placeholder": {
      color: "white",
    },
  },
  formTimeHour: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
  },
  listAddTrailer: {
    width: "100%",
    marginTop: "-8px",
    height: "calc( 100vh - 234px)",
  },
  itemAddTrailer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "92px",
    borderTop: "2px solid #535f78",
  },
  imageTrailer: {
    marginLeft: 8,
  },
  nameTrainer: {
    flex: 1,
    marginLeft: 16,
  },
  countUpsellItem: {
    padding: 6,
    "&.MuiIconButton-root.Mui-disabled": {
      color: "rgba(255,255,255,0.5) !important",
    },
  },
  countUpsell: {
    padding: 6,
  },
  iconCount: { fontSize: 28 },
  btnAddTrailer: {
    width: "83px",
  },
  hidden: {
    display: "none",
  },
  input: {
    display: "none",
  },
  process: {
    "&.MuiMobileStepper-root": {
      padding: "0",
    },
    "& .MuiMobileStepper-progress": {
      width: "100%",
      padding: 0,
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#c4c4c4",
    },
  },
}));
const detailsStep = [
  {
    step: 1,
    title: "Edit our Licensee's personal details",
    subTitle: "Personal Details",
  },
  {
    step: 2,
    title: "Edit our Licensee's Identification",
    subTitle: "Drivers License and Proof of incorporation",
  },
  {
    step: 3,
    title: "Edit our Licensee’s Business Docs",
    subTitle: "Business Documents",
  },
  {
    step: 4,
    title: "Edit our Licensee’s work address",
    subTitle: "Work address",
  },
  {
    step: 5,
    title: "Edit our Licensee’s working hours",
    subTitle: "Working days and Hours",
  },
  {
    step: 6,
    title: "Edit our Licensee’s trailers",
    subTitle: "Select trailers",
  },
  {
    step: 7,
    title: "Edit our Licensee’s upsell items",
    subTitle: "Select upsell items",
  },
  {
    step: 8,
    title: "Send invite to the Licensee App",
    subTitle: "Send invite to the Licensee",
  },
];
