import React, { useState, useEffect } from "react";
import LayoutAddOrEdit from "../../components/LayoutAddOrEdit";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ButtonCircle } from "../../components/ButtomCircle";
import { Typography, Button } from "@material-ui/core";
import { useLoading } from "../../hooks/useLoading";
import axios from "../../helpers/axios";
import { toast } from "react-toastify";
import ItemTrailerFeatured from "./ItemTrailerFeatured";
function _deepClone(result = [], array) {
  return array.forEach((element) => {
    result.push(JSON.parse(JSON.stringify(element)));
  });
}
const ChangeTrailerFeatures = () => {
  let history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [trailerList, setTrailerList] = useState([]);
  const [showLoading, hideLoading] = useLoading();
  const isTrailerSelected = (trailerId) => {
    // location.state.trailer is data from featured
    // trailerId is id of each trailer from "get-all-trailer"
    // NOTE: trailer could be NULL because dont have feadtured don't have any trailer
    if (location.state.trailer !== null) {
      return location.state.trailer._id === trailerId && true;
    }
    return false;
  };
  const handleSelectTrailer = (trailerSelected) => {
    const cloneTrailerList = [];
    _deepClone(cloneTrailerList, trailerList);
    // console.log(trailerSelected._id);
    cloneTrailerList.forEach((item) => {
      if (item.isSelected === true) {
        return (item.isSelected = false);
      }
      // if ( item._id === trailerSelected._id ) {
      //   return item.isSelected = true
      // }
    });
    cloneTrailerList[trailerSelected.index] = {
      ...cloneTrailerList[trailerSelected.index],
      isSelected: true,
    };
    setTrailerList(cloneTrailerList);
  };

  const handleSubmitTrailer = async () => {
    showLoading()
    const trailerId = trailerList.find(
      (element) => element.isSelected === true
    )._id;
    try {
      const res = await axios.put(
        `v1/admin/feature-trailer/${params.featureId}`,
        { trailerId }
      );
      toast.success(res?.data?.message);
      hideLoading();
      history.goBack();
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };
  useEffect(() => {
    axios.get("/v1/admin/get-all-trailers").then((response) => {
      return setTrailerList(
        response.data.dataObj.map((trailer) => {
          return {
            _id: trailer._id,
            name: trailer.name,
            photos: trailer.photos,
            isSelected: isTrailerSelected(trailer._id),
          };
        })
      );
    });
  }, []);
  return (
    <LayoutAddOrEdit titleDetail={`Select a featured trailer`}>
      <div className="btn-exit">
        <ButtonCircle variant="contained" onClick={() => history.goBack()}>
          Exit
        </ButtonCircle>
      </div>
      <div className="form-main">
        <Typography variant="h3">Select feature trailer</Typography>
        <div className="list-add-trailer">
          {trailerList.map((item, index) => (
            <ItemTrailerFeatured
              key={index}
              name={item.name}
              isChecked={item.isSelected}
              urlImage={item.photos[0]?.url}
              trailer={{ _id: item._id, index: index }}
              onSelectTrailer={handleSelectTrailer}
            />
          ))}
        </div>
        <div className="footter-process">
          <div className="btn-add-trailer">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmitTrailer()}
            >
              Add Trailer
            </Button>
          </div>
        </div>
      </div>
    </LayoutAddOrEdit>
  );
};
export default ChangeTrailerFeatures;
